import { SuperAction } from "../SuperAction";
import Request from "../../../api/Request";
import { someError, errorNotice, successNotice } from "../../reducers/notices/action";
import {onFinishSignDogovor} from "../Dogovor/actions";
import RequestCrmApi from "../../../api/RequestCrmApi";

export class creatingConfirmation extends SuperAction {}
export class createdConfirmation extends SuperAction {}
export class failureCreatingConfirmation extends SuperAction {}

export class finishingConfirmation extends SuperAction {}
export class finishedConfirmation extends SuperAction {}
export class failureFinishingConfirmation extends SuperAction {}

export function createConfirmation(reason = 'SIGN_DOGOVOR', phone, dogovorId, crm = false) {
  if (crm) {
    return dispatch => {
      dispatch(new creatingConfirmation());
      RequestCrmApi.post('/confirmation/create', { reason, phone, dogovorId })
        .then(response => {
          if (response.error) {
            dispatch(errorNotice('Ошибка', response.error.message));
            return dispatch(new failureCreatingConfirmation());
          }

          return dispatch(new createdConfirmation());
        })
        .catch(err => {
          console.error(err);
        })
    }
  }
  return dispatch => {
    dispatch(new creatingConfirmation());
    Request.post('/confirmation/create', { reason, phone, dogovorId })
      .then(response => {
        if (response.error) {
          dispatch(errorNotice('Ошибка', response.error.message));
          return dispatch(new failureCreatingConfirmation());
        }

        return dispatch(new createdConfirmation());
      })
      .catch(err => {
        console.error(err);
      })
  }
}

export function finishConfirmation(reason = 'SIGN_DOGOVOR', password, crm = false) {
  if (crm) {
    return dispatch => {
      dispatch(new finishingConfirmation());
      RequestCrmApi.post('/confirmation/confirm', { reason, password })
        .then(response => {
          if (response.error) {
            dispatch(errorNotice('Ошибка', response.error.message));
            return dispatch(new failureFinishingConfirmation());
          }
          console.log(response);
          if (response.result) {
            dispatch(successNotice('Договор', "Успешно подписан"));
            dispatch(new onFinishSignDogovor());
          } else {
            dispatch(errorNotice('Ошибка', "Проверьте SMS код"));
          }

          return dispatch(new finishedConfirmation());
        })
        .catch(err => {
          console.error(err);
          return dispatch(someError());
        })
    }
  }
  return dispatch => {
    dispatch(new finishingConfirmation());
    Request.post('/confirmation/confirm', { reason, password })
      .then(response => {
        if (response.error) {
          dispatch(errorNotice('Ошибка', response.error.message));
          return dispatch(new failureFinishingConfirmation());
        }
        console.log(response);
        if (response.result) {
          dispatch(successNotice('Договор', "Успешно подписан"));
          dispatch(new onFinishSignDogovor());
        } else {
          dispatch(errorNotice('Ошибка', "Проверьте SMS код"));
        }

        return dispatch(new finishedConfirmation());
      })
      .catch(err => {
        console.error(err);
        return dispatch(someError());
      })
  }
}

export class onChangeConfirmationPassword extends SuperAction {}

export function changeConfirmationPassword (value) {
  return new onChangeConfirmationPassword({ value });
}
