import React from 'react';
import { Link } from 'react-router-dom';
import mainStyle from "../../Templates/MainTemplate/MainTemplate.module.css";

class SuperReactComponent extends React.Component {

  getStringTime (stringTime) {
    if (stringTime === undefined || stringTime === null) {
      return '';
    }
    let now = new Date();
    if (typeof now.getDate !== 'function') {
      now = new Date(now);
    }
    const time = new Date(stringTime);
    let baseString = '';

    const printMinutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : `${time.getMinutes()}`;

    // Если в течение последних 6ти часов
    const sub = now.getTime() - time.getTime();
    if (sub < 0) {
      baseString = time.getDate() + " " + this.getMonthById(time.getMonth()) + " в " + time.getHours() + ":" + printMinutes;
    } else if (sub < 3600000) {
      if (sub < 120000) {
        baseString = "Сейчас";
      } else if ((sub > 120000) && (sub < 3600000)) {
        baseString =  Math.floor(sub / 1000 / 60) + this.changeStringByCount(Math.floor(sub / 1000 / 60), " мин назад", " мин назад", " мин назад");
      } else {
        baseString =  Math.floor(sub / 1000 / 60 / 60) + this.changeStringByCount(Math.floor(sub / 1000 / 60 / 60), " час назад", " часа назад", " часов назад");
      }
      //Если прошло более 6ти часов
    } else {
      if ((time.getDate() === now.getDate()) && (time.getMonth() === now.getMonth()) && (time.getUTCFullYear() === now.getUTCFullYear())) {
        baseString = `Сегодня в ${time.getHours()}:${printMinutes}`;
      } else {
        if ((now.getDate() - time.getDate() === 1) && (time.getMonth() === now.getMonth()) && (time.getUTCFullYear() === now.getUTCFullYear())) {
          baseString = "Вчера в " + time.getHours() + ":" + printMinutes;
        } else {
          if (time.getUTCFullYear() === now.getUTCFullYear()) {
            baseString = time.getDate() + " " + this.getMonthById(time.getMonth()) + " в " + time.getHours() + ":" + printMinutes;
          } else {
            baseString = time.getUTCFullYear() + "г. " + time.getDate() + " " + this.getMonthById(time.getMonth()) + " в " + time.getHours() + ":" + printMinutes;
          }
        }
      }
    }

    return baseString;
  }

  getStringDate (stringTime) {
    if (stringTime === undefined || stringTime === null) {
      return '';
    }
    let now = new Date();
    if (typeof now.getDate !== 'function') {
      now = new Date(now);
    }
    const time = new Date(stringTime);
    let baseString = `${time.getDate()} ${this.getMonthById(time.getMonth())}`
    if (now.getFullYear() !== time.getFullYear()) {
      baseString += ` ${time.getFullYear()}`;
    }
    return baseString;
  }

  getActionDate() {
    let now = new Date();
    // return now.getDate()+ ' ' + this.getMonthById(now.getMonth()) + ' ' + now.getUTCFullYear() + ' г.';
    if (now.getDate() < 5) {
      return '5 ' + this.getMonthById(now.getMonth()) + ' ' + now.getUTCFullYear() + ' г.';
    } else if (now.getDate() < 10) {
      return '10 ' + this.getMonthById(now.getMonth()) + ' ' + now.getUTCFullYear() + ' г.';
    } else if (now.getDate() < 20){
      return '20 ' + this.getMonthById(now.getMonth()) + ' ' + now.getUTCFullYear() + ' г.';
    } else if (now.getDate() < 31) {
      return '30 ' + this.getMonthById(now.getMonth()) + ' ' + now.getUTCFullYear() + ' г.';
    } else {
      return '31 ' + this.getMonthById(now.getMonth()) + ' ' + now.getUTCFullYear() + ' г.';
    }
  }

  showCurrentDate() {
    let now = new Date();
    // if (typeof now.getDate !== 'function') {
    //   now = new Date(now);
    // }
    return now.getDate() + " " + this.getMonthById(now.getMonth()) + " " + now.getUTCFullYear() + " г";
  }

  // Округление
  roundPlus(x, n) { //x - число, n - количество знаков
    if(isNaN(x) || isNaN(n)) return 0;
    var m = Math.pow(10,n);
    return Math.round(x*m)/m;
  }


  getMonthById(id) {
    let nameOfMonth = "";
    if (id === 0) nameOfMonth = "января";
    else if (id === 1) nameOfMonth = "февраля";
    else if (id === 2) nameOfMonth = "марта";
    else if (id === 3) nameOfMonth = "апреля";
    else if (id === 4) nameOfMonth = "мая";
    else if (id === 5) nameOfMonth = "июня";
    else if (id === 6) nameOfMonth = "июля";
    else if (id === 7) nameOfMonth = "августа";
    else if (id === 8) nameOfMonth = "сентября";
    else if (id === 9) nameOfMonth = "октября";
    else if (id === 10) nameOfMonth = "ноября";
    else if (id === 11) nameOfMonth = "декабря";
    else nameOfMonth = "";

    return nameOfMonth;
  }

  getStringByMinutes(id) {
    id = Math.abs(id);
    if ((id > 4) && (id < 21)) return id + " минут назад";
    if (id % 10 === 1) return id + " минуту назад";
    if (id % 10 === 2) return id + " минуты назад";
    if (id % 10 === 3) return id + " минуты назад";
    if (id % 10 === 4) return id + " минуты назад";
    return id + " минут назад";
  }

  /**
   * Склонение строк
   * @param count количество по которому склоняет
   * @param one для чисел 1, 21, 31, 41 ....
   * @param two для чисел 2, 3, 4, 22-24, 32-34 ....
   * @param three для чисел 5-20, 25-30, 35-40 ....
   * @returns {*}
   */
  changeStringByCount(count, one, two, three) {
    const lastDecade = count % 100;
    const lastNumber = lastDecade % 10;

    if (lastNumber === 1 && lastDecade !== 11) {
      return one;
    }
    if ((lastNumber > 1 && lastNumber < 5) && (lastDecade < 10 || lastDecade > 20)) {
      return two;
    }
    return three;
  }

  getStatusColor(id) {
    switch (id) {
      case 0:
        return '#ffdddd';
      case 1:
        return '#ffffff';
      case 2:
        return '#f9f4ca';
      case 3:
        return '#cae9f9';
      // case 4:
      //   return '#cae9f9';
      // case 5:
      //   return '#caf9f9';
      case 6:
        return '#d3f9ca';
      case 7:
        return '#d3f9ca';
      case 8:
        return '#d3f9ca';
      case 9:
        return '#d3f9ca';
      case 10:
        return '#d3f9ca';
      case 15:
        return '#ffdddd';
      case 16:
        return '#ffdddd';
      case 17:
        return '#ffdddd';
      case 18:
        return '#ffdddd';
    }
  }

  getTwoSymbolsDate(date) {
    if (date.length === 1) {
      return `0${date}`
    }
    return date;
  }

  getPaymentLinkBySumm(summ) {
    let link = null;
    
    switch (summ.toString()) {
      case '1000':
        link = 'https://securepayments.sberbank.ru/shortlink/7oLjusCJ';
        break;
      case '1900':
        link ='https://securepayments.sberbank.ru/shortlink/gQSGmkl3';
        break;
      case '2000':
        link = 'https://securepayments.sberbank.ru/shortlink/iKuJNEgU';
        break;
      case '2500':
        link = 'https://securepayments.sberbank.ru/shortlink/mFRChumz';
        break;
      case '3000':
        link = 'https://securepayments.sberbank.ru/shortlink/9yj2looB';
        break;
      case '3250':
        link = 'https://securepayments.sberbank.ru/shortlink/bwygCkK2';
        break;
      case '3500':
        link = 'https://securepayments.sberbank.ru/shortlink/xXTJ2Ers';
        break;
      case '4000':
        link = 'https://securepayments.sberbank.ru/shortlink/jicNX154';
        break;
      case '4500':
        link = 'https://securepayments.sberbank.ru/shortlink/23XfvUCs';
        break;
      case '4600':
        link = 'https://securepayments.sberbank.ru/shortlink/j8JG71SW';
        break;
      case '5000':
        link = 'https://securepayments.sberbank.ru/shortlink/IXcIMbwX';
        break;
      case '5250':
        link = 'https://securepayments.sberbank.ru/shortlink/2xkQgBaU';
        break;
      case '5500':
        link = 'https://securepayments.sberbank.ru/shortlink/KZTzgAcN';
        break;
      case '5750':
        link = 'https://securepayments.sberbank.ru/shortlink/KmNQoxVo';
        break;
      case '6000':
        link = 'https://securepayments.sberbank.ru/shortlink/FBb2jZdo';
        break;
      case '6500':
        link = 'https://securepayments.sberbank.ru/shortlink/S3E7YUDW';
        break;
      case '7000':
        link = 'https://securepayments.sberbank.ru/shortlink/XYOdPTjq';
        break;
      case '7500':
        link = 'https://securepayments.sberbank.ru/shortlink/emwrT2qp';
        break;
      case '7750':
        link = 'https://securepayments.sberbank.ru/shortlink/PDZn7KfY';
        break;
      case '8000':
        link = 'https://securepayments.sberbank.ru/shortlink/3zTn6rDS';
        break;
      case '9000':
        link = 'https://securepayments.sberbank.ru/shortlink/BrP6VNr7';
        break;
      case '9500':
        link = 'https://securepayments.sberbank.ru/shortlink/GuEHVbjd';
        break;
      case '9750':
        link = 'https://securepayments.sberbank.ru/shortlink/GssRc0B3';
        break;
      case '10000':
        link = 'https://securepayments.sberbank.ru/shortlink/rFYGgxzt';
        break;
      case '10500':
        link = 'https://securepayments.sberbank.ru/shortlink/xw7tQqt9';
        break;
      case '25000':
        link = 'https://securepayments.sberbank.ru/shortlink/3Y4XMT3L';
        break;
      case '26000':
        link = 'https://securepayments.sberbank.ru/shortlink/x3G4d5tM';
        break;
      case '27000':
        link = 'https://securepayments.sberbank.ru/shortlink/1StDJ6Mc';
        break;
      default:
        link = null;
        break;
    }
    return link;
  }
}


export default SuperReactComponent;

