import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {deleteNotice} from "../../../store/reducers/notices/action";
import NoticesListTemplate from "./Templates/NoticesListTemplate";

class Notices extends React.Component {
  handleDeleteNotice = (id) => {
    this.props.deleteNotice(id);
  }

  render() {
    return (
      <div>
        <NoticesListTemplate
          list={ this.props.list }
          onDeleteNotice={this.handleDeleteNotice}
        />
      </div>
    );
  }
}

Notices.propTypes = {
  list: PropTypes.array,
  // functions
  deleteNotice: PropTypes.func
}

export default connect(
  state => {
    return {
      list: state.notices.list
    };
  },
  dispatcher => bindActionCreators({
    deleteNotice
  }, dispatcher)
)(Notices);
