import React from 'react';
import style from './Oplata.module.css';
import mainStyle from '../../../Templates/MainTemplate/MainTemplate.module.css';
import PropTypes from "prop-types";
import SuperReactComponent from "../../Super/SuperReactComponent";

class OplataTemplate extends SuperReactComponent {

  render() {
    console.log(this.getPaymentLinkBySumm(this.props.summ));
    return (
      <div className={mainStyle.page}>
        <div className={mainStyle.centered}>
          <h1>
            Оплата
          </h1>

          <div className={style.flex}>
            <div className={style.info}>
              <div className={style.in}>
                Для Вас сформирован счёт на сумму {this.props.summ} рублей.
                <br /><br />
                Оплата юридических услуг компании "Полезный юрист".
                <div className={style.acceptLayout}>
                  <a href={this.getPaymentLinkBySumm(this.props.summ)} target='_blank' className={style.acceptButton}>
                    Перейти к оплате
                  </a>
                </div>
              </div>
              <a href={"/documenti/pravila"} className={style.link}>Правила сайта</a>
              <a href={"/documenti/politika-konfidencialnosti"} className={style.link}>Политика конфиденциальности</a>
            </div>

            <div className={style.dopInfo}>
              <div className={style.in}>
                <div className={style.dopInfoTitle}>
                  Дополнительная информация
                </div>
                <div className={style.dopInfoSubTitle}>
                  Способы оплаты
                </div>
                Банковской картой
                <br/> <br/>
                Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку Оплата заказа банковской картой. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем:
                <img src={'/src/images/ps_colour.png'} />
                <div className={style.dopInfoSubTitle}>
                  Возврат товара
                </div>
                Срок возврата товара надлежащего качества составляет 30 дней с момента получения товара.
                <br/><br/>
                Возврат переведённых средств, производится на ваш банковский счёт в течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).
                <div className={style.dopInfoSubTitle}>
                  Описание процесса передачи данных
                </div>
                Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.
                <br/><br/>
                Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

OplataTemplate.propTypes = {
  summ: PropTypes.string,
};

export default OplataTemplate;
