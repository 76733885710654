import React from 'react';
import PageInfo from "../Services/PageInfo";
import Notices from "../Services/Notices";
import Route from "../Services/Route";
import Scripts from "../Services/Scripts";
import ClientServices from "../../api/ClientServices";
import Popup from "../Services/Popup";

class Services extends React.Component{
  render(){
    if (ClientServices.isBrowser()) {
      return(
        <div id={'services'}>
          <PageInfo/>
          <Notices/>
          <Route/>
          <Scripts/>
          <Popup/>
        </div>
      );
    }
    return (
      <div id={'services'}>
      </div>
    );
  }
}

export default Services;
