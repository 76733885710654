import { OnSetDefaultState } from "../../reducers/auth/actions";
import {OnClickClosePopup, OnClosePopup, OnShowPopup} from "../../asyncReducers/Popup/actions";

class Popup {
  static DefaultState = {
    name: '',
    data: {},
    isCanCloseOnClick: true
  }

  static reducer(state = Popup.DefaultState, action = {}){
    switch (action.type) {
      case OnSetDefaultState.type:
        return Popup.defaultState;
      case OnShowPopup.type:
        const { name, data } = action.payload;
        return {
          ...state,
          name,
          data
        };
      case OnClosePopup.type:
        return Popup.DefaultState;
      case OnClickClosePopup.type:
        if (state.isCanCloseOnClick) {
          return Popup.DefaultState;
        }
        return state;
      default:
        return state;
    }
  }
}

export default Popup;
