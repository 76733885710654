import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import PaymentsPageTemplate from "./Templates/PaymentsPageTemplate";


class Payments extends Component {
  render(){
    return (
      <PaymentsPageTemplate payments={this.props.payments} client={this.props.client}/>
    )
  }
}

Payments.defaultProps = {
  payments: [],
  client: {}
}

Payments.propTypes = {
  payments: PropTypes.array,
  client: PropTypes.object
}

export default connect(
  _state => {
    const state = {};
    if (_state.auth.info) {
      state.payments = _state.auth.info.payments;
      state.client = _state.auth.info
    }
    return state;
  },
  dispatch => bindActionCreators({}, dispatch)
)(Payments);