import React from 'react';
import PropTypes from 'prop-types';
import mainStyle from "../../../Templates/MainTemplate/MainTemplate.module.css";
import style from "./Dogovor.module.css";

class DogovorPreviewTemplate extends React.Component {
    render(){
        return (
            <div className={mainStyle.page}>
                <div className={mainStyle.centered}>
                    <div className={style.dogovorPage}>
                        <h1>
                            ДОГОВОР ОКАЗАНИЯ ЮРИДИЧЕСКИХ УСЛУГ
                        </h1>

                        <div className={style.dogovor}>
                            <div className={style.inDogovor}>
                                <center>
                                    <a href={'https://office.bankrotstvo-fiz-lic.ru/files/base/docTemplates/doc_example.pdf'} target={'_blank'}>
                                        Открыть образец договора в PDF
                                    </a>
                                </center>
                            </div>
                            <div>
                                <div className={style.acceptLayout}>
                                    <div className={style.acceptButton} onClick={this.props.onNextStep}>
                                        Перейти к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

DogovorPreviewTemplate.propTypes = {
    onNextStep: PropTypes.func
}

export default DogovorPreviewTemplate;
