import {
    OnChangeAnswer,
    OnChangeQuizContactFormField,
    OnChangeQuizStep,
    SendedQuiz,
    SendingQuiz
} from "../Quiz/actions";
import {StartRender} from "../../reducers/application/actions";
import {OnClickClosePopup, OnClosePopup} from "../Popup/actions";

class Quiz {
    static DefaultState = {
        step: 1,
        answers: {},
        contactForm: {
            sending: false,
            fields: {
                name: '',
                phone: ''
            }
        }
    }

    static reducer = (state = Quiz.DefaultState, action = {type: '', action: {}}) => {
        switch (action.type) {
            case StartRender.type:
                return Quiz.DefaultState;
            case OnChangeAnswer.type:
                const { question, answer } = action.payload;
                return {
                    ...state,
                    step: state.step + 1,
                    answers: {
                        ...state.answers,
                        [question.id]: {
                            q: question.content,
                            a: answer.content,
                            id: answer.id
                        }
                    }

                }
            case OnChangeQuizStep.type:
                const { direction } = action.payload;
                if (direction === 'next') {
                    return {
                        ...state,
                        step: state.step + 1
                    }
                }
                return {
                    ...state,
                    step: state.step - 1
                }
            case OnChangeQuizContactFormField.type:
                const { name, value } = action.payload;
                return {
                    ...state,
                    contactForm: {
                        ...state.contactForm,
                        fields: {
                            ...state.contactForm.fields,
                            [name]: value
                        }
                    }
                }
            case SendingQuiz.type:
                return {
                    ...state,
                    contactForm: {
                        ...state.contactForm,
                        sending: true
                    }
                }
            case SendedQuiz.type:
                return {
                    ...state,
                    step: state.step + 1,
                    contactForm: {
                        ...state.contactForm,
                        sending: false
                    }
                }
            case OnClosePopup.type:
                return Quiz.DefaultState;
            case OnClickClosePopup.type:
                return Quiz.DefaultState;
            default:
                return state;
        }
    }
}

export default Quiz;
