import React from 'react';
import PropTypes from 'prop-types';
import mainStyle from "../../../Templates/MainTemplate/MainTemplate.module.css";
import style from "./Dogovor.module.css";
import SuperReactComponent from "../../Super/SuperReactComponent";

class DogovorFinPrintTemplate extends SuperReactComponent {
  handleChangeConfirmationPassword = (event) => {
    this.props.onChangeConfirmationPassword(event.target.value);
  }

  render(){
    return (
      <div className={mainStyle.page}>
        <div className={mainStyle.centered}>
          <div className={style.dogovorPage}>
            <h1>
              ДОГОВОР ОКАЗАНИЯ ЮРИДИЧЕСКИХ УСЛУГ
            </h1>
            {
              this.props.thumbnails.map(thumbnail => {
                return <img src={`${process.env.REACT_APP_WEB_URL}${thumbnail}`} />;
              })
            }
            <div className={style.acceptLayout}>
              {
                !this.props.sendedSMS?
                <div className={style.acceptButton} onClick={this.props.onStartSignDogovor}>
                  Получить код для подписи
                </div>
                :
                <div>
                  <input
                    placeholder={'СМС код'}
                    onChange={this.handleChangeConfirmationPassword}
                    value={this.props.confirmationPassword}
                  />

                  <div className={style.acceptButton} onClick={this.props.onFinishSignDogovor}>
                    Отправить
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DogovorFinPrintTemplate.propTypes = {
  form: PropTypes.object,
  confirmationPassword: PropTypes.string,
  step: PropTypes.number,
  sendedSMS: PropTypes.bool,
  signed: PropTypes.bool,
  price: PropTypes.number,
  thumbnails: PropTypes.array,
  // functions
  onStartSignDogovor: PropTypes.func,
  onFinishSignDogovor: PropTypes.func,
  onChangeConfirmationPassword: PropTypes.func
}

export default DogovorFinPrintTemplate;
