import React from 'react';
import { setPageInfo } from "../../store/actions/PageInfo";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentiPravilaTemplate from "./Templates/DocumentiPravilaTemplate";

class DocumentiPravila extends React.Component {
    constructor(props) {
        super(props);
        props.setPageInfo({
            title: 'Правила сайта | Полезный юрист',
            description: 'Правила сайта | Полезный юрист'
        })
    }

    render() {
        return (
            <DocumentiPravilaTemplate/>
        );
    }
}

DocumentiPravila.propTypes = {
    // functions
    setPageInfo: PropTypes.func,
};

export default connect(
    state => {
        return {}
    },
    dispatcher => bindActionCreators({ setPageInfo }, dispatcher)
)(DocumentiPravila);
