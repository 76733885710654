import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';

import { Spinner } from 'react-bootstrap';
class PageLoadingTemplate extends React.Component {
  render() {
    return (
      <div className={style.loadingTemplate}>
        {
          this.props.loading ?
            // Если загружается
            <Spinner animation="border" variant={this.props.variant} />
            :
            // Если нет элементов списка
            this.props.itemsCount === 0 ?
              <div className={style.empty}>
                <div className={style.text}>
                  Здесь пока ничего нет
                </div>
              </div>
              :
              null
        }
      </div>
    );
  }
}

PageLoadingTemplate.defaultProps = {
  variant: "primary"
}

PageLoadingTemplate.propTypes = {
  loading: PropTypes.bool,
  itemsCount: PropTypes.number,
  variant: PropTypes.string
};

export default PageLoadingTemplate;
