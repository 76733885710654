import React from 'react';
import { setPageInfo } from "../../store/actions/PageInfo";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentiPolitikaKonfidencialnostiTemplate from "./Templates/DocumentiPolitikaKonfidencialnostiTemplate";

class DocumentiPolitikaKonfidencialnosti extends React.Component {
    constructor(props) {
        super(props);
        props.setPageInfo({
            title: 'Политика конфиденциальности | Полезный юрист',
            description: 'Политика конфиденциальности | Полезный юрист'
        })
    }

    render() {
        return (
            <DocumentiPolitikaKonfidencialnostiTemplate/>
        );
    }
}

DocumentiPolitikaKonfidencialnosti.propTypes = {
    // functions
    setPageInfo: PropTypes.func,
};

export default connect(
    state => {
        return {}
    },
    dispatcher => bindActionCreators({ setPageInfo }, dispatcher)
)(DocumentiPolitikaKonfidencialnosti);
