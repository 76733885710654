import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import * as reducers from './reducers';
import Storage from "../api/Storage";
import Const from '../api/Const';
import ClientServices from '../api/ClientServices';

let store = {};
const jwtToken = Storage.get(Const.jwtToken, null);
const defaultState = {
  auth: {
    isAuth:  jwtToken !== 'undefined' && jwtToken !== null,
    id: Storage.get(Const.userId, undefined),
    name: Storage.get(Const.userName, undefined),
    token: Storage.get(Const.jwtToken, undefined),
  },
  pageInfo: {},
  route: {},
  application: {},
  notices: {
    list: []
  }
};

function setToDefault(initialState = {}) {
  const reducer = combineReducers(reducers);

  // Набор мидлов для клиента
  const createStoreWithMiddleware = applyMiddleware(
      reduxThunk
  )(createStore);

  let preloadedState;

  if (process.env.NODE_ENV !== 'production' || true) {
    store = createStoreWithMiddleware(
        reducer,
        initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    store = createStoreWithMiddleware(
        reducer,
        preloadedState ? preloadedState : initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // todo:  убрать, что бы на продакшене был не виден стэйт
    );
  }

  store.asyncReducers = {};
}

setToDefault(defaultState);

/**
 * Вставка асинхронного редьюсера
 */
const injectAsyncReducer =  (name, asyncReducer) => {
  if (store.asyncReducers[name]) {
    return;
  }
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

const injectObjectAsyncReducer = (objectAsyncReducer = {}) => {
  if (store.asyncReducers[objectAsyncReducer.name]) {
    return false;
  }
  store.asyncReducers[objectAsyncReducer.name] = objectAsyncReducer.reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return true;
}

const injectObjectNamedAsyncReducer = (name, objectAsyncReducer = {}) => {
  if (store.asyncReducers[`${objectAsyncReducer.name}_${name}`]) {
    return;
  }
  store.asyncReducers[`${objectAsyncReducer.name}_${name}`] = objectAsyncReducer.reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
}

/**
 * Создание редьюсера
 * @param asyncReducers
 * @returns {Reducer<any> | Reducer<any, AnyAction>}
 */
const createReducer = (asyncReducers = {}) => {
  return combineReducers({
    ...reducers,
    ...asyncReducers
  });
};

export {
  store,
  defaultState,
  injectAsyncReducer,
  injectObjectAsyncReducer,
  injectObjectNamedAsyncReducer
};

