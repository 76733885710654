import React from 'react';
import PropTypes from "prop-types";
import { Row, Col, Card } from 'react-bootstrap';
import SuperReactComponent from "../../Super/SuperReactComponent";

class PaymentsGrapthicItem extends SuperReactComponent {
    getBorder = () => {
        if (this.props.isFinished) {
            return 'success';
        }

        if (this.props.date && this.props.date.getTime() < Date.now()) {
            return 'danger';
        }

        return 'warning';
    }
    render () {
        const border='warning';

        return(
          <Row>
              <Col sm={'12'} style={{paddingBottom: '5px'}}>
                  <Card border={this.getBorder()} text={'dark'}>
                      <Card.Body>{this.props.desc.toLocaleString() + ' руб'} {this.props.date ? this.getStringTime(this.props.date) : 'Дата не согласована'}</Card.Body>
                  </Card>
              </Col>
          </Row>
        )
    }
}

PaymentsGrapthicItem.propTypes = {
    step: PropTypes.number,
    desc: PropTypes.string,
    date: Date,
    isFinished: PropTypes.bool,
};

export default PaymentsGrapthicItem;