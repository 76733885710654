import ClientServices from './ClientServices';

class Metrika extends ClientServices {
  static goal(name = '', useGoogle = true){
    const window = ClientServices.getWindow();
    if (typeof window.yaCounter54352276 !== "undefined") {
      window.yaCounter54352276.reachGoal(name);
    }
    if (useGoogle) {
      const _tmr = window._tmr || (window._tmr = []);
      _tmr.push({ id: "3134884", type: "reachGoal", goal: "firststep" })
      const { gtag } = window;
      if (typeof gtag !== 'undefined') {
        gtag('event', 'conversion', {
          'send_to': 'AW-869071967/73H3CLyyjKgBEN_4s54D',
          'transaction_id': ''
        });
      }
    }
  }

}

export default Metrika;
