import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProcessItemTemplate from "./ProcessItemTemplate";
import { Accordion } from "react-bootstrap";
import ProcessPageTemplate from "./ProcessPageTemplate";

class ProcessListTemplate extends Component {
  render(){
    return (
      <Accordion>
        {
          this.props.documents.map(document => {
            return <ProcessItemTemplate
              key={document.id}
              document={document}
              documentCategory={this.props.documentCategory}
              taskTypes={this.props.taskTypes}
            />
          })
        }
      </Accordion>
    )
  }
}

ProcessListTemplate.propTypes = {
  documents: PropTypes.array,
  documentCategory: PropTypes.array,
  taskTypes: PropTypes.array
}

export default ProcessListTemplate;