import React from 'react';
import PropTypes from 'prop-types';
import style from './Quiz.module.css';

class QuizQuestionStepTemplate extends React.Component{
  static propTypes = {
    step: PropTypes.number,
    question: PropTypes.string,
    currentAnswer: PropTypes.object,
    // functions
    onSelectAnswer: PropTypes.func
  }

  handleSelectAnswer = (answer) => {
    this.props.onSelectAnswer(this.props.question, answer);
  }

  render(){
    const { step, question, currentAnswer } = this.props;
    return(
      <div className={style.questionBlock}>
        <div className={style.stepAndQuestionInQuestionBlock}>
          <div className={style.stepInQuestionBlock}>
            {step}.
          </div>
          <div className={style.questionInQuestionBlock}>
            {question.content}
          </div>
        </div>
        <div className={style.answersBlock}>
          {question.answers.map(answer => {
            return (
              <div className={style.answers} onClick={() => { this.handleSelectAnswer(answer)}}>
                {
                  currentAnswer.id === answer.id ?
                    <div className={style.answerGalochka}>
                      ✔
                    </div>
                    :
                    <div className={style.answerGalochka}>
                      ✔
                    </div>
                }
                <div className={style.answerText}>
                  <span>{answer.content}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  };
}

export default QuizQuestionStepTemplate;