import React from 'react';
import PropTypes from 'prop-types';
import mainStyle from "../../../Templates/MainTemplate/MainTemplate.module.css";
import style from "./Dogovor.module.css";
import SuperReactComponent from "../../Super/SuperReactComponent";

class DogovorPrintTemplate extends SuperReactComponent {
  handleChangeConfirmationPassword = (event) => {
    this.props.onChangeConfirmationPassword(event.target.value);
  }

  render(){
    let prices;
    console.log(this.props.price);
    switch(this.props.price){
      case 48000:
        prices = {
          string: '48 000',
          number: 48000,
          stringLine: 'сорок восемь'
        }
        break;
      case 50000:
        prices = {
          string: '50 000',
          number: 50000,
          stringLine: 'пятьдесят'
        }
        break;
      case 54000:
        prices = {
          string: '54 000',
          number: 54000,
          stringLine: 'пятьдесят четыре'
        }
        break;
      case 57000:
        prices = {
          string: '57 000',
          number: 57000,
          stringLine: 'пятьдесят семь'
        }
        break;
      default:
        prices = {
          string: '60 000',
          number: 60000,
          stringLine: 'шестьдесят'
        }
    }
    console.log(prices);
    const date = new Date(this.props.form.birthday);
    const year = date.getFullYear();
    let month = 0;
    if (date.getMonth() + 1 < 10) {
      month = date.getMonth()+1;
      month = '0' + month;
    } else {
      month = date.getMonth()+1;
    }
    const day = date.getDate();

    return (
      <div className={mainStyle.page}>
        <div className={mainStyle.centered}>
          <div className={style.dogovorPage}>
            <h1>
              ДОГОВОР ОКАЗАНИЯ ЮРИДИЧЕСКИХ УСЛУГ
            </h1>

            <div className={style.dogovor}>
              <div className={style.inDogovor}>
                <div className={style.flex}><div className={style.left}>г. Киров</div><div className={style.right}>{ this.showCurrentDate() }</div></div>
                <br /><br />
                <div className={style.flex}><strong>{ this.props.form.surname + " " + this.props.form.name + " " + this.props.form.secondname }, {day}.{month}.{year} г.р.</strong></div>
                Зарегистрированный(-ая) по адресу: <strong>{ this.props.form.address }</strong> , именуемый в дальнейшем <strong>«Заказчик»</strong>, с одной стороны и Общество с ограниченной ответственностью <strong>«Полезный Юрист»</strong> в лице директора Колегова Алексея Николаевича, именуемое в дальнейшем <strong>«Исполнитель»</strong>, с другой стороны, заключили настоящий договор о нижеследующем:
                <div className={style.h2}>
                  1. Предмет договора
                </div>
                1.1. По настоящему договору Исполнитель обязуется по заданию Заказчика и за его счет оказать услуги, указанные в п.1.2. настоящего договора, а Заказчик обязуется оплачивать эти услуги в соответствии и на условиях, предусмотренных настоящим договором.
                <br /><br />
                1.2. Исполнитель обязуется осуществить правовое и консультационное сопровождение процедуры банкротства <strong>{ this.props.form.bSurname + " " + this.props.form.bName + " " + this.props.form.bSecondname }</strong>, а именно составить исчерпывающий список организаций и подготовить запросы на получение информации в форме справок, необходимой для составления искового заявления, подготовить заявление о банкротстве, предоставить консультации на всех стадиях процедуры банкротства, в том числе на стадии реализации и(или) реструктуризации, осуществить подбор СРО и согласовать участие финансового управляющего в деле о банкротстве, <strong>получить итоговое решение (определение) суда об освобождении от исполнения обязательств либо утверждение плана реструктуризации</strong>.
                <br /><br />
                1.3. Неотъемлемой частью настоящего Договора является собственноручно подписанное Заказчиком «Заявление на диагностику перспективы проведения процедуры Банкротства и просьбу заключить настоящий Договор».
                <div className={style.h2}>
                  2. Порядок расчетов
                </div>
                2.1. По соглашению Сторон стоимость оказываемых в соответствии с настоящим договором услуг составляет {prices.string} руб. ({prices.stringLine} тысяч) рублей.
                <br /><br />
                2.2. Расчет по настоящему договору производится в следующем порядке: путем внесения всей суммы, указанной в п. 2.1, до подписания договора на счет Исполнителя.
                <br /><br />
                2.3. Расчет между Сторонами производится в безналичном порядке.
                <br /><br />
                2.4 В случае невозможности исполнения Заказчиком п. 2.2 услуги оплачиваются в рассрочку по следующему графику: ежемесячно в сумме <strong>{ Math.round(prices.number*100 / this.props.form.installmentPeriod) / 100 } руб.</strong> на протяжении <strong>{ this.props.form.installmentPeriod } месяцев</strong>.
                <div className={style.h2}>
                  3. Права и обязанности сторон
                </div>
                <strong>3.1. Исполнитель обязан:</strong>
                <br /><br />
                3.1.1. При выполнении поручений Заказчика в отношениях с третьими лицами действовать от имени Заказчика и за его счет.
                <br /><br />
                3.1.2. Оказывать услуги с надлежащим качеством и в полном объеме, предусмотренном настоящим договором.
                <br /><br />
                3.1.3. Сообщать Заказчику по его требованию все сведения о ходе исполнения данного им поручения.
                <br /><br />
                3.1.4. Следовать указаниям Заказчика.
                <br /><br />
                <strong>3.2. Заказчик обязан:</strong>
                <br /><br />
                3.2.1.1. Производить оплату согласно условиям, указанным в разделе 2 настоящего договора.
                <br /><br />
                3.2.1.2. В течение 15 (пятнадцати) дней с момента уведомления (любым способом: по телефону, в виде электронного письма, в виде СМС) о готовности искового заявления в Арбитражный суд оплатить государственную пошлину в размере 300 рублей и разместить на депозите Арбитражного суда 25.000 рублей для оплат услуг финансового управляющего. Предоставить Исполнителю в этот срок скан-копию чеков указанных платежных документов.
                <br /><br />
                3.2.1.3. В течение 15 (пятнадцати) дней с момента уведомления (любым способом: по телефону, в виде электронного письма, в виде СМС) об утверждении финансового управляющего перевести по предоставленным дополнительно реквизитам судебные расходы в размере 30.000 рублей. И предоставить Исполнителю в этот срок скан-копию чеков указанных платежных документов.
                <br /><br />
                3.2.2. Предоставить в распоряжение Исполнителя всю необходимую для выполнения данных им поручений информацию и документацию по первому требованию Исполнителя.
                <br /><br />
                3.2.3. Оплачивать счета о внесении сумм государственных сборов, пошлин, а также иных обязательных платежей, предъявляемых Исполнителю государственными органами и иными организациями в процессе исполнения поручения Заказчика, не позднее следующего дня с момента предъявления Исполнителем соответствующего счета или квитанции.
                <br /><br />
                3.2.4. Оказывать содействие в осуществлении деятельности Исполнителем.
                <br /><br />
                3.2.5. Не вмешиваться в деятельность Исполнителя в ходе выполнения им данного Заказчиком поручения.
                <br /><br />
                3.2.6. Выдавать доверенность на имя Исполнителя или третьих лиц по его указанию с полномочиями, необходимыми по усмотрению Исполнителя для оказания услуг по настоящему договору.
                <br /><br />
                3.3. Исполнитель вправе оказать услуги лично или с привлечением третьих лиц без дополнительного (в том числе предварительного) согласования с Заказчиком.
                <br /><br />
                3.4. Заказчик имеет право отказаться от исполнения настоящего договора в любое время при условии письменного уведомления Исполнителя. При этом в случае отказа от исполнения Договора заказчиком в срок до 14 дней с момента подписания, Исполнитель имеет право вернуть денежные средства Заказчику в полном объеме за вычетом понесенных расходов. В случае отказа Заказчика от исполнения настоящего Договора по истечению 14 дней после заключения Договора, денежные средства Заказчику не возвращаются.
                <br /><br />
                3.5. Исполнитель имеет право в течение всего срока действия настоящего договора отказаться от его исполнения при условии предоставления Заказчиком недостоверных данных либо отказе предоставления требуемой информации в виде документов, справок, выписок, пояснений, уведомив при этом Заказчика любым способом: по телефону, в виде электронного письма, в виде СМС. С момента получения указанного уведомления Заказчиком настоящий договор считается расторгнутым, стороны освобождаются от исполнения обязательств, денежные средства Заказчику не возвращаются.
                <div className={style.h2}>
                  4. Ответственность сторон
                </div>
                4.1. По настоящему договору стороны несут ответственность в соответствии с нормами законодательства, действующего на территории России.
                <br /><br />
                4.2. Исполнитель не несет ответственность за неисполнение или ненадлежащее исполнение обязанностей, предусмотренных настоящим договором, в случае, если такое неисполнение возникло вследствие нарушения Заказчиком своих обязанностей, взятых на себя им в соответствии с условиями настоящего договора, в том числе непредставлением Исполнителю всей необходимой информации и документации, имеющей существенное значение для выполнения поручения, и предоставление неполной либо недостоверной информации.
                <br /><br />
                4.3. Исполнитель несет ответственность за достижение результата, указанного в п. 1.2., а в случае не достижения обязуется вернуть полученные средства, а также организовать возврат расходов, указанных в п. 3.2.
                <div className={style.h2}>
                  5. Порядок разрешения споров
                </div>
                5.1. Споры и разногласия, возникшие при исполнении настоящего договора, разрешаются путем переговоров между сторонами посредством направления претензии.
                <br /><br />
                5.2. В случае невозможности разрешения споров путем переговоров спор подлежит рассмотрению в суде по месту нахождения Исполнителя.
                <div className={style.h2}>
                  6. Заключительные положения
                </div>
                6.1. Любые изменения и дополнения к настоящему договору действительны лишь при условии, что они совершены в письменной форме и подписаны уполномоченными на то представителями сторон.
                <br /><br />
                6.2. Во всем, что не урегулировано настоящим договором, Стороны руководствуются действующим законодательством Российской Федерации.
                <br /><br />
                6.3. Настоящий договор составлен в двух экземплярах, имеющих одинаковую силу, по одному каждой стороне.
                <br /><br />
                6.4. Настоящий договор вступает в силу с момента подписания и действует до полного исполнения сторонами принятых в соответствии с ним обязательств.
                <br /><br />
                6.5. Стороны пришли к соглашению о том, что факсимильное изображение печати и подписи сторон приравнивается к печати и подписи выполненному собственноручно и влечет те же последствия. А волеизъявление Заказчика на заключение данного договора может быть выражено в форме.
                <br /><br />
                6.6. Заказчик уведомлен и не возражает, что при проведении телефонных переговоров будет осуществляться аудиозапись.
                <br /><br />
                6.7. Стороны пришли к соглашению о том, что волеизъявление Заказчика на заключение настоящего Договора в отсутствие возможности подписания способом, указанным в п. 6.5, может быть выражено в подписании Договора специальным кодом (простой электронной подписью), полученным в SMS-сообщении от Исполнителя и приравнивается к подписи выполненному собственноручно и влечет те же последствия.
                <div className={style.h2}>
                  7. Адреса, реквизиты и подписи сторон
                </div>
                <table className={style.table}>
                  <tr>
                    <td>
                      <strong>Исполнитель</strong>
                    </td>
                    <td>
                      <strong>Заказчик</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ООО «Полезный Юрист»
                    </td>
                    <td>
                      <strong>{ this.props.form.surname + " " + this.props.form.name + " " + this.props.form.secondname }</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Юридический адрес: 610002, г. Киров, ул.Ленина 103а, оф 406
                    </td>
                    <td>
                      <strong>{ this.props.form.address }</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ИНН 4345461289/ ОГРН 1174350002310
                    </td>
                    <td>
                      <strong>Паспорт: { this.props.form.passportSeria + " " + this.props.form.passportNumber }</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      КПП 434501001
                    </td>
                    <td>
                      <strong>Выдан: { this.props.form.passportIssuedBy }</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Получатель: ООО «Полезный Юрист»
                    </td>
                    <td>
                      <strong>Дата выдачи: { this.props.form.whenReceivedPassport }</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Счет получателя: 40702810127000006193
                    </td>
                    <td>
                      <strong>Контактный тел.: { this.props.form.phone }</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Банк получателя: Кировское отделение
                      <br />
                      №8612 ПАО "СБЕРБАНК"
                      <br />
                      ИНН 7707083893
                      <br />
                      БИК 043304609
                      <br />
                      Кор.счет № 30101810500000000609
                    </td>
                    <td>

                    </td>
                  </tr>
                  <tr>
                    <td>
                      Директор А.Н. Колегов
                      <br /><br />
                      <img src={"https://office.bankrotstvo-fiz-lic.ru/src/images/pechat.jpg"} />
                      ___________________________
                    </td>
                    <td>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div className={style.acceptLayout}>
              {
                !this.props.sendedSMS?
                <div className={style.acceptButton} onClick={this.props.onStartSignDogovor}>
                  Подписать
                </div>
                :
                <div>
                  <input
                    placeholder={'СМС код'}
                    onChange={this.handleChangeConfirmationPassword}
                    value={this.props.confirmationPassword}
                  />

                  <div className={style.acceptButton} onClick={this.props.onFinishSignDogovor}>
                    Отправить
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DogovorPrintTemplate.propTypes = {
  form: PropTypes.object,
  confirmationPassword: PropTypes.string,
  step: PropTypes.number,
  sendedSMS: PropTypes.bool,
  signed: PropTypes.bool,
  price: PropTypes.number,
  // functions
  onStartSignDogovor: PropTypes.func,
  onFinishSignDogovor: PropTypes.func,
  onChangeConfirmationPassword: PropTypes.func
}

export default DogovorPrintTemplate;
