import React from 'react';
import ClientServices from "../../../api/ClientServices";

class Scripts extends React.Component{
  componentDidMount(){
    if (ClientServices.isBrowser()) {
      setTimeout(() => {
        console.log('Start inject scripts');
        const scripts = [
          // TalkMe
         /* `(function(d, w, m) {
          window.supportAPIMethod = m;
          var s = d.createElement('script');
          s.type ='text/javascript'; s.id = 'supportScript'; s.charset = 'utf-8';
          s.async = true;
          var id = '9a5a4f68423a06329681db76c86a8b27';
          s.src = '//lcab.talk-me.ru/support/support.js?h='+id;
          var sc = d.getElementsByTagName('script')[0];
          w[m] = w[m] || function() { (w[m].q = w[m].q || []).push(arguments); };
          if (sc) sc.parentNode.insertBefore(s, sc);
          else d.documentElement.firstChild.appendChild(s);
        })(document, window, 'TalkMe');`,*/
          // Rating MailRu
          `var _tmr = window._tmr || (window._tmr = []);
          _tmr.push({id: "3134884", type: "pageView", start: (new Date()).getTime()});
          (function (d, w, id) {
            if (d.getElementById(id)) return;
            var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
            ts.src = "https://top-fwz1.mail.ru/js/code.js";
            var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
            if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
          })(document, window, "topmailru-code");`,
          // Yandex Metrika
          /*`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        
          ym(54352276, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          });`,*/
          // Google Metrika Additional
          `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-869071967');`,
          // Vk
          `!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?161",t.onload=function(){VK.Retargeting.Init("VK-RTRG-386404-8nVSa"),VK.Retargeting.Hit()},document.head.appendChild(t)}();`,
          // Roistat
          `(function(w, d, s, h, id) {
                    w.roistatProjectId = id; w.roistatHost = h;
                    var p = d.location.protocol == "https:" ? "https://" : "http://";
                    var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init";
                    var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
                  })(window, document, 'script', 'cloud.roistat.com', '8f0f92e690a70747182b01ce12e2d2d7');`
        ];
        const document = ClientServices.getDocument();
        scripts.map(script => {
          const domScript = document.createElement('script');
          domScript.async = 1;
          domScript.text = script;
          document.head.append(domScript);
        });
        const srcScripts = [
          `https://www.googletagmanager.com/gtag/js?id=AW-869071967`,
          // `https://cdn.envybox.io/widget/cbk.js?wcb_code=d68a15806079fa3158633cd5ea2f2dcb`
        ]
        srcScripts.forEach(srcScript => {
          const domSrcScript = document.createElement('script');
          domSrcScript.async = 1;
          domSrcScript.src = srcScript;
          document.head.append(domSrcScript);
        })
      }, 3000)
    }
  }
  render(){
    return null;
  }
}

export default Scripts;