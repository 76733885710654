import React from 'react';
import PropTypes from 'prop-types';
import DogovorFinPrintSignedTemplate from "./DogovorFinPrintSignedTemplate";
import DogovorFinFormTemplate from "./DogovorFinFormTemplate";
import DogovorFinPrintTemplate from "./DogovorFinPrintTemplate";
import DogovorFinPreviewTemplate from "./DogovorFinPreviewTemplate";

class DogovorFinTemplate extends React.Component {
  render() {
    let thumbnails = [], downloadPath = '';
    thumbnails = this.props.paths.thumbnails
    downloadPath = this.props.paths.downloadPath;
    switch (this.props.step) {
      case 0:
        return (
          <DogovorFinPreviewTemplate
            type={this.props.type}
            onNextStep={this.props.onNextStep}
          />
        );
      case 1:
        return (
          <DogovorFinFormTemplate
            form={this.props.form}
            loading={this.props.loading}
            onChangeField={this.props.onChangeField}
            onCreateDogovor={this.props.onCreateDogovor}
          />
        );
      case 2:
        return (
          <DogovorFinPrintTemplate
            sendedSMS={false}
            thumbnails={thumbnails}
            onStartSignDogovor={this.props.onStartSignDogovor}
            onFinishSignDogovor={this.props.onFinishSignDogovor}
          />
        );
      case 3:
        return (
          <DogovorFinPrintTemplate
            sendedSMS={true}
            thumbnails={thumbnails}
            confirmationPassword={this.props.confirmationPassword}
            onStartSignDogovor={this.props.onStartSignDogovor}
            onFinishSignDogovor={this.props.onFinishSignDogovor}
            onChangeConfirmationPassword={this.props.onChangeConfirmationPassword}
          />
        );
      case 4:
        return (
          <DogovorFinPrintSignedTemplate
            downloadPath={downloadPath}
          />
        );
      default:
        return (
          <DogovorFinFormTemplate
            form={this.props.form}
            onNextStep={this.props.onNextStep}
          />
        );
    }
  }
}
DogovorFinTemplate.propTypes = {
  type: PropTypes.string,
  form: PropTypes.object,
  confirmationPassword: PropTypes.string,
  step: PropTypes.number,
  price: PropTypes.number,
  paths: PropTypes.object,
  loading: PropTypes.bool,
  // functions
  onChangeField: PropTypes.func,
  onCreateDogovor: PropTypes.func,
  onStartSignDogovor: PropTypes.func,
  onFinishSignDogovor: PropTypes.func,
  onChangeConfirmationPassword: PropTypes.func,
  onNextStep: PropTypes.func
}

export default DogovorFinTemplate;
