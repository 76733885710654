import React from 'react';
import PropTypes from 'prop-types';
import { injectObjectAsyncReducer } from "../../../../store";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuizReducer from '../../../../store/asyncReducers/Quiz';
import { changeQuizStep, changeAnswer, changeQuizContactFormField, sendQuiz } from "../../../../store/asyncReducers/Quiz/actions";
import QuizTemplate from "../../../Services/Popup/Quiz/Templates/QuizTemplate";
import ClientServices from "../../../../api/ClientServices";
import { startRedirect } from "../../../../store/reducers/route/actions";

class Quiz extends React.Component {
  static propTypes = {
    step: PropTypes.number,
    answers: PropTypes.array,
    contactForm: PropTypes.object,
    // functions
    changeQuizStep: PropTypes.func,
    changeAnswer: PropTypes.func,
    onClickClosePopup: PropTypes.func,
    changeQuizContactFormField: PropTypes.func,
    sendQuiz: PropTypes.func,
    startRedirect: PropTypes.func
  }

  constructor(props){
    super(props);
    injectObjectAsyncReducer(QuizReducer);
  }

  questions = [
    {},
    {
      id: 1,
      content: 'Сумма Вашего долга?',
      answers: [{
        id: 0,
        content: 'До 150 000 рублей'
      }, {
        id: 1,
        content: '150 000 - 300 000 рублей'
      }, {
        id: 2,
        content: '300 000 - 500 000 рублей'
      }, {
        id: 3,
        content: 'Более 500 000 рублей'
      }]
    },{
      id: 2,
      content: 'Вы в браке?',
      answers: [{
        id: 0,
        content: 'Есть муж'
      }, {
        id: 1,
        content: 'Есть жена'
      }, {
        id: 2,
        content: 'Нет'
      }]
    }, {
      id: 3,
      content: 'Вы можете исполнять свои долговые обязательства?',
      answers: [{
        id: 0,
        content: 'Да'
      }, {
        id: 1,
        content: 'Нет'
      }]
    }, {
      id: 4,
      content: 'Вас беспокоят коллектора и отделы взыскания?',
      answers: [{
        id: 0,
        content: 'Да'
      }, {
        id: 1,
        content: 'Да, меня и моих близких'
      }, {
        id: 2,
        content: 'Нет'
      }]
    }, {
      id: 5,
      content: 'Ваш официальный доход?',
      answers: [{
        id: 0,
        content: 'Нет дохода'
      }, {
        id: 1,
        content: 'До 20 000 рублей'
      }, {
        id: 2,
        content: '20 000 - 40 000 рублей'
      }, {
        id: 3,
        content: 'Более 40 000 рублей'
      }]
    }, {
      id: 6,
      content: 'Судебные приставы удерживают Ваш доход?',
      answers: [{
        id: 0,
        content: 'Да'
      }, {
        id: 1,
        content: 'Нет'
      }]
    }, {
      id: 7,
      content: 'Являетесь ли Вы пенсионером?',
      answers: [{
        id: 0,
        content: 'Да'
      }, {
        id: 1,
        content: 'Нет'
      }]
    }, {
      id: 8,
      content: 'У Вас есть дети до 18 лет?',
      answers: [{
        id: 0,
        content: 'Нет детей'
      }, {
        id: 1,
        content: 'Есть 1 ребёнок'
      }, {
        id: 2,
        content: 'Есть 2 или более детей'
      }]
    }
  ]

  handleNextStep = () => {
    this.props.changeQuizStep('next');
  }

  handlePrevStep = () => {
    this.props.changeQuizStep('previous');
  }

  handleSendQuiz = () => {
    this.props.sendQuiz(
      this.props.answers,
      this.props.contactForm.fields
    );
  }

  handleClickClosePopup = () => {
    if (ClientServices.isBrowser()) {
      const window = ClientServices.getWindow();
      if (window.location.pathname === '/quiz') {
        this.props.startRedirect('/');
        this.props.onClickClosePopup();
      } else {
        this.props.onClickClosePopup();
      }
    }
  }
  
  render() {
    const  { step, answers, changeAnswer, onClickClosePopup, contactForm } = this.props;
    return (
      <QuizTemplate
        step={step}
        answers={answers}
        questions={this.questions}
        onNextStep={this.handleNextStep}
        onPrevStep={this.handlePrevStep}
        onChangeAnswer={changeAnswer}
        onClickClosePopup={this.handleClickClosePopup}
        onChangeField={this.props.changeQuizContactFormField}
        contactForm={contactForm.fields}
        onClickSendRequest={this.handleSendQuiz}
      />
    );
  }
}

export default connect(
  _state => {
    const quiz = _state[QuizReducer.name];
    let state = {
      step: 1,
      answers: [],
      contactForm: {
        sending: false,
        sended: false,
        fields: {
          name: '',
          value: ''
        }
      }
    }

    if (quiz) {
      state.step = quiz.step;
      state.answers = quiz.answers;
      state.contactForm = quiz.contactForm;
    }

    return state;
  },
  dispatcher => bindActionCreators({
    changeQuizStep,
    changeAnswer,
    changeQuizContactFormField,
    sendQuiz,
    startRedirect
  }, dispatcher)
)(Quiz);