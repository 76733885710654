import React from 'react';
import PropTypes from 'prop-types';
import mainStyle from "../../../Templates/MainTemplate/MainTemplate.module.css";
import style from "./Dogovor.module.css";
import SuperReactComponent from "../../Super/SuperReactComponent";

class DogovorFinPrintSignedTemplate extends SuperReactComponent {
  render(){
    return (
      <div className={mainStyle.page}>
        <div className={mainStyle.centered}>
          <div className={style.dogovorPage}>
            <h1>
              ДОГОВОР ОКАЗАНИЯ ЮРИДИЧЕСКИХ УСЛУГ
            </h1>
            <div>
              <a href={this.props.downloadPath}>
                Скачать договор
              </a>
            </div>
            <div className={style.acceptLayout}>
              <div>
                Благодарим за обращение в нашу компанию!
                <br/><br/>
                Вы можете быть уверены в решение своего вопроса!
                <br/><br/>
                С уважением, компаниния "Полезный юрист"!
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DogovorFinPrintSignedTemplate.propTypes = {
  downloadPath: PropTypes.string
}

export default DogovorFinPrintSignedTemplate;
