import { OnChangeFormValue } from "../Form/actions";
import {OnAnketaGetPrice, OnAnketaGetResults} from "../Anketa/actions";

class Anketa {
  static defaultState = {
    summDolg: '150000',
    summDohod: '15000',
    monthSummDolg: '9000',
    result: {
       newMonthSummDolg: '4500',
       newSummDolg: '150000'
    },
    state: 0
  };

 static reducer = (state = Anketa.defaultState, action = {}) => {
    switch(action.type) {
      case OnAnketaGetPrice.type:
        return {
          ...state,
          state: 2
        }
      case OnAnketaGetResults.type:
        const r = Number(state.summDohod) - 10753;
        const mnG = 0.25*Number(state.summDohod);
        const mxG = 0.5*Number(state.summDohod);
        if (mxG < r) {
          return {
            ...state,
            result: {
              ...state.result,
              newSummDolg: state.summDolg,
              newMonthSummDolg: mxG
            },
            state: 1
          }
        }
        if (mnG < r) {
          return {
            ...state,
            result: {
              ...state.result,
              newSummDolg: state.summDolg,
              newMonthSummDolg: `${r} - ${mxG}`
            },
            state: 1
          }
        }
        return {
          ...state,
          result: {
            ...state.result,
            newSummDolg: state.summDolg,
            newMonthSummDolg: `${mnG} - ${mxG}`,
            state: 1
          }
        }
      case OnChangeFormValue.type:
        const { reducerName } = action.payload;
        if (reducerName === Anketa.name) {
          const { fieldName, fieldValue } = action.payload;
          return {
            ...state,
            [fieldName]: fieldValue
          }
        }
        return state;
      default:
        return state;
    }
 }
}

export default Anketa;