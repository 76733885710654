import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import InfoTemplate from "./Templates/InfoTemplate";

class Info extends React.Component {
  render(){
    return (
     <InfoTemplate {...this.props.info}/>
    )
  }
}
export default connect(
  _state => {
    return {
      info: _state.auth.info
    }
  },
  dispatch => bindActionCreators({}, dispatch)
)(Info);
