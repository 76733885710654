import React from 'react';
import PropTypes from 'prop-types';
import style from './Quiz.module.css';
import InputMask from 'react-input-mask';

class QuizContactsTempalte extends React.Component{
    constructor(props) {
        super(props);
        this.state = { error: false };
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'phone') {
            if (value === '') {
                this.setState({ error: true });
            } else {
                this.setState({ error: false });
            }
        }
        this.props.onChangeField(name, value);
    }
    handleClickSendQuiz = () => {
        if (this.props.phone === '') {
            this.setState({ error: true });
        } else {
            this.setState({ error: false });
        }
        this.props.onClickSendRequest();
    }
    getHeaderText = (answerId) => {
        switch (answerId) {
            case 0:
                return (
                    <div className={style.contactH1Title}>
                        За 5 лет к нам обратились более 10 000 человек с долгом до 150 000 рублей. Наши юристы помогли всем!
                        <br/>
                        <br/>
                        Оставьте контакты и мы расскажем Вам как уменьшить долг.
                    </div>
                )
            case 1:
                return (
                    <div className={style.contactH1Title}>
                        За 5 лет к нам обратились более 10 000 человек с долгом от 150 000 рублей до 300 000 рублей. Наши юристы помогли всем!
                        <br/>
                        <br/>
                        Оставьте контакты и мы расскажем Вам как уменьшить долг.
                    </div>
                )
            case 2:
                return (
                    <div className={style.contactH1Title}>
                        За 5 лет к нам обратились более 10 000 человек с долгом от 300 000 рублей до 500 000 рублей. Наши юристы помогли всем!
                        <br/>
                        <br/>
                        Оставьте контакты и мы расскажем Вам как уменьшить долг.
                    </div>
                )
            case 3:
                return (
                    <div className={style.contactH1Title}>
                        За 5 лет к нам обратились более 10 000 человек с долгом от 500 000 рублей. Наши юристы помогли всем!
                        <br/>
                        <br/>
                        Оставьте контакты и мы расскажем Вам как списать долг.
                    </div>
                )
            default:
                return (
                    <div className={style.contactH1Title}>
                        За 5 лет к нам обратились более 10 000 человек. Наши юристы помогли всем!
                        <br/>
                        <br/>
                        Оставьте контакты и мы расскажем Вам как списать долг.
                    </div>
                )
        }
    }
    render(){
        const { phone, name } = this.props;
        console.log(this.state);
        return(
            <div className={style.contact}>
                { this.getHeaderText(this.props.answerId) }
                <InputMask
                    className={style.contactInput}
                    onChange={this.handleChange}
                    name={'phone'}
                    mask={'+7 (999) 999 99 99'}
                    placeholder={'+7 (***) *** ** **'}
                    maskChar={'*'}
                    value={phone}
                    style={{
                        fontSize: '20px',
                        marginBottom: '20px'
                    }}
                />
                {
                    this.state.error ?
                        "Необходимо ввести номер телефона"
                        :
                        null
                }
                {/*<input
                    className={style.contactInput}
                    onChange={this.handleChange}
                    name={'name'}
                    placeholder={'Имя'}
                    value={name}
                />*/}
                <div className={style.contactButton} onClick={this.handleClickSendQuiz}>
                    {/*Получить бесплатную консультацию*/}
                    Узнать стоимость
                </div>
            </div>
        );
    };
}

QuizContactsTempalte.propTypes = {
    answerId: PropTypes.number,
    step: PropTypes.string,
    phone: PropTypes.string,
    name: PropTypes.string,
    // functions
    onChangeField: PropTypes.func,
    onChangeState: PropTypes.func,
    onClickSendRequest: PropTypes.func
};

export default QuizContactsTempalte;