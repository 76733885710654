import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import NoticesItemTemplate from "./NoticesItemTemplate";

class NoticesListTemplate extends React.Component {
  render(){
    return (
      <div className={style.list}>
        {
          this.props.list.map(item => {
            return <NoticesItemTemplate key={item.id} item={item} onDeleteNotice={this.props.onDeleteNotice}/>;
          })
        }
      </div>
    );
  }
}

NoticesListTemplate.propTypes = {
  list: PropTypes.array,
  // functions
  onDeleteNotice: PropTypes.func
}

export default NoticesListTemplate;
