import { SuperAction } from '../SuperAction';
import { someError, errorNotice, successNotice } from "../../reducers/notices/action";

import Metrika from "../../../api/Metrika";
import UserStorage from "../../../api/UserStorage";
import RequestCrmApi from "../../../api/RequestCrmApi";

class onChangeFieldFirstStageFrom extends SuperAction {};

function changeFieldFirstStageForm(name, value) {
  return new onChangeFieldFirstStageFrom({ name, value });
}

class onChangeMonths extends SuperAction {};

function changeMonths(months) {
  return new onChangeMonths({ months })
}

class SendingRequest extends SuperAction {};
class SendedRequest extends SuperAction {};
class FailureSendingRequest extends SuperAction {};

function sendRequest(name, phone) {
  return dispatch => {
    if (!phone) {
      return dispatch(
        errorNotice('Ошибка заполнения', 'Пожалуйста, заполните контактный номер телефона')
      );
    }
    return new Promise((success, error) => {
      dispatch(new SendingRequest());

      const user = UserStorage.get();

      RequestCrmApi.post('/clients/add', {
        client: {
          name,
          phone,
          utm: JSON.parse(user.utm)
        }
      })
        .then(response => {
          if (response.error) {
            console.error(response);
          }

          Metrika.goal('firststep');
          console.log('repsonse after first request');
          console.log(response);

          dispatch(new SendedRequest(response))
          return dispatch(successNotice('Ваша заявка отправлена', 'В ближайшее время с Вами свяжутся'));
        })
        .catch(err => {
          console.error(err);
          // dispatch(someError());
          return dispatch(successNotice('Ваша заявка отправлена', 'В ближайшее время с Вами свяжутся'));
        })
    });
  }
}

export {
  changeFieldFirstStageForm,
  onChangeFieldFirstStageFrom,
  changeMonths,
  onChangeMonths,
  SendedRequest,
  SendingRequest,
  FailureSendingRequest,
  sendRequest
}
