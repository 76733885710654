import React from 'react';
import PropTypes from 'prop-types';

import style from './style.module.css';

class AuthFormTemplate extends React.Component {
  handleClickAuth = () => {
    this.props.onClickAuth();
  };

  handleChangeValue = (event) => {
    this.props.onChangeInputValue(event.target.name, event.target.value);
  };

  render() {
    return (
        <div className={style.authForm}>
          <div className={style.auth}>
            <h1 className={style.title}>
              Вход в личный кабинет
            </h1>
            <div className={style.input}>
              <span>Телефон</span>
              <input
                  id='login'
                  name='login'
                  onChange={this.handleChangeValue}
                  value={this.props.login}
              />
            </div>
            <div className={style.input}>
              <span>Пароль</span>
              <input
                  id='password'
                  name='password'
                  type={"password"}
                  onChange={this.handleChangeValue}
                  value={this.props.password}
              />
            </div>
            <div className={style.buttonLayout}>
              <div className={style.button} onClick={this.handleClickAuth}>
                Вход
              </div>
            </div>
          </div>
          <div className={style.dopInfo}>
            В личном кабинете Клиенты "Полезного Юриста" могут:
            <ul>
              <li>
                запросить бесплатную консультацию юриста;
              </li>
              <li>
                посмотреть на какой стадии находится дело Клиента;
              </li>
              <li>
                оплатить услуги по карте;
              </li>
              <li>
                пройти диагностику на возможность признания банкротом;
              </li>
              <li>
                подписать договор на оказание услуг онлайн.
              </li>
            </ul>
          </div>
        </div>
    );
  }
}

AuthFormTemplate.propTypes = {
  login: PropTypes.string,
  password: PropTypes.string,
  isViewPassword: PropTypes.bool,
  // functions
  onChangeInputValue: PropTypes.func,
  onClickAuth: PropTypes.func,
  onChangeViewPassword: PropTypes.func
};

export default AuthFormTemplate;
