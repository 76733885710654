import UserStorage from "../../../api/UserStorage";
import { SuperAction } from "../../asyncReducers/SuperAction";


export class onLogout extends SuperAction {}

export function logout() {
  UserStorage.remove();

  return new onLogout({});
}

export class OnSetDefaultState extends SuperAction {};
export function setDefaultState(){
  return new OnSetDefaultState();
}
export class OnAutoAuth extends SuperAction {};
export function autoAuth(user, token) {
  return new OnAutoAuth({ user, token });
}