import UserStorage from "./UserStorage";
import Storage from './Storage';
import Const from "./Const";
import ClientServices from "./ClientServices";

class UTM {
  // static meTalkIntervalId;

  static utm = {};

  static set() {

    const window = ClientServices.getWindow();

    window.roistatVisitCallback = UTM.setRoistatId;
    window.saveRoibestVisitIdCallback = UTM.setRoibestId;

    const userId = Storage.get(Const.userId, false);

    if (userId) {
      return;
    }

    const search = window.location.search.substring(1);
    const params = search.split('&');

    params.forEach(param => {
      const values = param.split('=');
      UTM.utm[values[0]] = decodeURI(values[1]);
    });
  }

  static setRoistatId(roistatId){
    console.log(`try set roistatId ${roistatId}`);
    UTM.utm.roistatId = roistatId;
    UserStorage.set({
      utm: UTM.utm,
    })
  }

  static setRoibestId(roibestId) {
    console.log(`setted roibest id ${roibestId}`);
    UTM.utm.roibestId = roibestId;
    UserStorage.set({
      utm: UTM.utm
    })
  }
}

export default UTM;
