import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import SuperReactComponent from "../../Super/SuperReactComponent";

class EventsItemTemplate extends SuperReactComponent {
  render(){
    const { task } = this.props;

    const types = this.props.taskType.filter(el => el.id === task.typeId);
    const type = types ? types[0] : null;
    return (
      <tr>
        <td>{ task.id }</td>
        <td>{type ? type.name : ''} { task.content }</td>
        <td>{ this.getStringTime(task.createdAt) }</td>
        <td>{ task.documentId }</td>
        <td>{ task.document.name }</td>
        <td>{ this.getStringTime(task.document.createdAt) }</td>
      </tr>
    )
  }
}

EventsItemTemplate.propTypes = {
  task: PropTypes.object,
  taskType: PropTypes.array
}

export default EventsItemTemplate;