import React from 'react';
import PropTypes from 'prop-types';
import style from "./Dogovor.module.css";
import mainStyle from "../../../Templates/MainTemplate/MainTemplate.module.css";

class DogovorFormTemplate extends React.Component {
  handleChangeField = (event) => {
    const { name, value } = event.target;
    return this.props.onChangeField(name, value);
  }

  render(){
    const {
      bSurname,
      bName,
      bSecondname,
      surname,
      name,
      secondname,
      birthday,
      phone,
      passportNumber,
      passportSeria,
      passportIssuedBy,
      whenReceivedPassport,
      address,
      installmentPeriod
    } = this.props.form;

    return (
        <div className={mainStyle.page}>
          <div className={mainStyle.centered}>
            <div className={style.dogovorPage}>

              <h1>
                ДОГОВОР ОКАЗАНИЯ ЮРИДИЧЕСКИХ УСЛУГ
              </h1>
              <div className={style.form}>
                <div className={style.inForm}>

                  <div className={style.title}>
                    Лицо, признаваемое банкротом
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Фамилия (в родительном падеже):
                    </div>
                    <input
                        name={'bSurname'}
                        value={bSurname}
                        placeholder={'Иванова'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Имя (в родительном падеже):
                    </div>
                    <input
                        name={'bName'}
                        value={bName}
                        placeholder={'Ивана'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Отчество (в родительном падеже):
                    </div>
                    <input
                        name={'bSecondname'}
                        value={bSecondname}
                        placeholder={'Ивановича'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.title}>
                    Заказчик
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Фамилия:
                    </div>
                    <input
                        name={'surname'}
                        value={surname}
                        placeholder={'Иванов'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Имя:
                    </div>
                    <input
                        name={'name'}
                        value={name}
                        placeholder={'Иван'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Отчество:
                    </div>
                    <input
                        name={'secondname'}
                        value={secondname}
                        placeholder={'Иванович'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Дата рождения:
                    </div>
                    <input
                        name={'birthday'}
                        value={birthday}
                        type={'date'}
                        placeholder={'01.01.1992'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Контактный телефон:
                    </div>
                    <input
                        name={'phone'}
                        value={phone}
                        placeholder={'7 912 123 45 67'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Серия паспорта:
                    </div>
                    <input
                        name={'passportSeria'}
                        value={passportSeria}
                        placeholder={'1234'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Номер паспорта:
                    </div>
                    <input
                        name={'passportNumber'}
                        value={passportNumber}
                        placeholder={'123456'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Кем выдан:
                    </div>
                    <input
                        name={'passportIssuedBy'}
                        value={passportIssuedBy}
                        placeholder={'УМВД Ленинского района города Москвы'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Когда выдан:
                    </div>
                    <input
                        name={'whenReceivedPassport'}
                        value={whenReceivedPassport}
                        type={'date'}
                        placeholder={'01.01.2010'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Адрес прописки:
                    </div>
                    <input
                        name={'address'}
                        value={address}
                        placeholder={'г. Москва, ул Ленина д.100 кв.1'}
                        onChange={this.handleChangeField}
                    />
                  </div>

                  <div className={style.formField}>
                    <div className={style.formFieldTitle}>
                      Расрочка на:
                    </div>
                    <input
                        name={'installmentPeriod'}
                        value={installmentPeriod}
                        placeholder={'1'}
                        onChange={this.handleChangeField}
                    />
                    <span>
                      мес.
                    </span>
                  </div>

                </div>
              </div>

              <div className={style.acceptLayout}>
                <div className={style.acceptButton} onClick={this.props.onCreateDogovor}>
                  Далее
                </div>
              </div>

            </div>
          </div>
        </div>
    )
  }
}

DogovorFormTemplate.propTypes = {
  form: PropTypes.object,
  // functions
  onChangeField: PropTypes.func,
  onCreateDogovor: PropTypes.func
};

export default DogovorFormTemplate;
