import React from 'react';
import PropTypes from 'prop-types';

import DogovorFormTemplate from "./DogovorFormTemplate";
import DogovorPrintTemplate from "./DogovorPrintTemplate";
import DogovorPrintSignedTemplate from "./DogovorPrintSignedTemplate";
import DogovorPreviewTemplate from "./DogovorPreviewTemplate";

class DogovorTemplate extends React.Component {
  render() {
    console.log(this.props.price);
    switch (this.props.step) {
      case 0:
        return (
          <DogovorPreviewTemplate
            onNextStep={this.props.onNextStep}
          />
        );
      case 1:
        return (
          <DogovorFormTemplate
            form={this.props.form}
            onChangeField={this.props.onChangeField}
            onCreateDogovor={this.props.onCreateDogovor}
          />
        );
      case 2:
        return (
          <DogovorPrintTemplate
            form={this.props.form}
            sendedSMS={false}
            price={this.props.price}
            onStartSignDogovor={this.props.onStartSignDogovor}
            onFinishSignDogovor={this.props.onFinishSignDogovor}
          />
        );
      case 3:
        return (
          <DogovorPrintTemplate
            form={this.props.form}
            sendedSMS={true}
            price={this.props.price}
            confirmationPassword={this.props.confirmationPassword}
            onStartSignDogovor={this.props.onStartSignDogovor}
            onFinishSignDogovor={this.props.onFinishSignDogovor}
            onChangeConfirmationPassword={this.props.onChangeConfirmationPassword}
          />
        );
      case 4:
        return (
          <DogovorPrintSignedTemplate
            form={this.props.form}
          />
        );
      default:
        return (
          <DogovorFormTemplate
            form={this.props.form}
            onNextStep={this.props.onNextStep}
          />
        );
    }
  }
}
DogovorTemplate.propTypes = {
  form: PropTypes.object,
  confirmationPassword: PropTypes.string,
  step: PropTypes.number,
  price: PropTypes.number,
  // functions
  onChangeField: PropTypes.func,
  onCreateDogovor: PropTypes.func,
  onStartSignDogovor: PropTypes.func,
  onFinishSignDogovor: PropTypes.func,
  onChangeConfirmationPassword: PropTypes.func,
  onNextStep: PropTypes.func
}

export default DogovorTemplate;
