import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from "../../store/reducers/auth/actions";
import Metrika from '../../api/Metrika';
import HeaderTemplate from "./Templates/HeaderTemplate";
import UserReducer from '../../store/asyncReducers/User';

class Header extends React.Component {
  handleClickPhone = () => {
    Metrika.goal('phone');
  };

  handleLogout = () => {
    this.props.logout();
  };

  render() {
    return (
      <HeaderTemplate
        isAuth={this.props.isAuth}
        name={this.props.name}
        user={this.props.user}
        loadedUser={this.props.loadedUser}
        onClickPhone={this.handleClickPhone}
        onClickLogout={this.handleLogout}
      />
    );
  }
}

Header.propTypes = {
  isAuth: PropTypes.bool,
  name: PropTypes.string,
  loadedUser: PropTypes.bool,
  user: PropTypes.object,
  // functions
  logout: PropTypes.func,
};

export default connect(
  state => {
    let result = {
      isAuth: state.auth.isAuth,
      name: state.auth.name,
      login: '',
      user: {},
      loadedUser: false
    };

    const user = state[UserReducer.name];
    if (user) {
      result = {
        ...result,
        user: user.info.item,
        loadedUser: user.info.loaded
      }
    }

    return result;
  },
  dispatch => bindActionCreators({logout}, dispatch)
)(Header);
