import { onFinishedRedirect, onStartRedirect } from "../../reducers/route/actions";
import {StartRender} from "../../reducers/application/actions";

const defaultState = {
  isNeedRedirect: false,
  path: null,
  doRoute: false
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case StartRender.type:
      return defaultState;
    case onStartRedirect.type:
      return {
        ...state,
        path: action.payload.path,
        doRoute: true
      };
    case onFinishedRedirect.type:
      return {
        ...state,
        isNeedRedirect: false,
        path: null,
        doRoute: false
      };
    default:
      return state;
  }
}