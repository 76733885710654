import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import EventsListTemplate from "./EventsListTemplate";

class EventsPageTemplate extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Col sm={12}>
            <h1>События</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <EventsListTemplate documents={this.props.documents} taskType={this.props.taskType}/>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

EventsPageTemplate.propTypes = {
  documents: PropTypes.array,
  taskType: PropTypes.array
}

export default EventsPageTemplate;