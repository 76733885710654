import {
  FailureSendingRequest,
  onChangeFieldFirstStageFrom, onChangeMonths,
  SendedRequest
} from "./actions";

class FirstStageForm {
  static DefaultState = {
    name: '',
    email: '',
    phone: '',
    months: 12,
    canRedirect: false
  }

  static reducer = (state = FirstStageForm.DefaultState, action = {}) => {
    switch (action.type) {
      case onChangeFieldFirstStageFrom.type:
        let canRedirect = false;
        if (
          state.name.length > 0
          && state.email.length > 0
          && state.phone.length > 0
        ) {
          canRedirect = true;
        }

        return {
          ...state,
          [action.payload.name]: action.payload.value,
          canRedirect
        }
      case onChangeMonths.type:
        return {
          ...state,
          months: action.payload.months
        }
      case SendedRequest.type:
        return FirstStageForm.DefaultState;
      case FailureSendingRequest.type:
        return state;
      default:
        return state;
    }
  }
}

export default FirstStageForm;
