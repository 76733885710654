import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import ForClientRoute from './Route';
import { Alert, Row, Col, Container, Card, ListGroup, ListGroupItem } from "react-bootstrap";

class Template extends React.Component {
    getDelayInfo = () => {
        switch (this.props.delayState) {
            case 1:
                return (
                    <Row>
                        <Col sm={12}>
                            <Alert variant={'warning'}>
                                У Вас просрочка платежа. Необходимо внести платежа или подать заявление о переносе даты платежа.
                            </Alert>
                        </Col>
                    </Row>
                );
            case 2:
                return (
                    <Row>
                        <Col sm={12}>
                            <Alert variant={'danger'}>
                                От Вас не поступали платежи более 60 дней. Работа остановлена до внесения следующего платежа. Обратитесь к персональному менеджеру.
                            </Alert>
                        </Col>
                    </Row>
                );
            default:
                return null;
        }
    }
    render() {
        return (
            <Container fluid>
                { this.getDelayInfo() }
                <Container>
                    <Row>
                        <Col lg={9} sm={12}>
                            <ForClientRoute/>
                        </Col>
                        <Col lg={3} sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <Card border={'primary'}>
                                        <Card.Img variant="top" src={this.props.ownerJurist.avatar} />
                                        <Card.Body>
                                            <Card.Title>{this.props.ownerJurist.name}</Card.Title>
                                            <Card.Text>
                                                Ответственный Юрист
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={12}>
                                    <Card border={'primary'} style={{ marginTop: '1rem'}}>
                                        <Card.Img variant="top" src={this.props.ownerSupportManagerFirst.avatar} />
                                        <Card.Body>
                                            <Card.Title>{this.props.ownerSupportManagerFirst.name}</Card.Title>
                                            <Card.Text>
                                                Помощник юриста
                                            </Card.Text>
                                        </Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem>{`Телефон: +${this.props.ownerSupportManagerFirst.workPhone}`}</ListGroupItem>
                                            <ListGroupItem>{`WhatsApp: +${this.props.ownerSupportManagerFirst.workPhone}`}</ListGroupItem>
                                            <ListGroupItem>{`Viber: +${this.props.ownerSupportManagerFirst.workPhone}`}</ListGroupItem>
                                        </ListGroup>
                                        {/*<Card.Body>
                                <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link>
                            </Card.Body>*/}
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}

Template.propTypes = {
    ownerJurist: PropTypes.object,
    ownerSupportManagerFirst: PropTypes.object,
    ownerSupportManagerSecond: PropTypes.object,
    delayState: PropTypes.number
}

export default connect(
    _state => {
        let state = {
            delayState: 0
        };
        if (_state.auth.info) {
            state.ownerJurist = _state.auth.info.ownerJurist;
            state.ownerSupportManagerFirst = _state.auth.info.ownerSupportManagerFirst;
            state.ownerSupportManagerSecond = _state.auth.info.ownerSupportManagerSecond;
            state.delayState = _state.auth.info.delayState
        }
        return state;
    },
    dispatch => bindActionCreators({}, dispatch)
)(Template);