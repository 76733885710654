import React from 'react';
import PropTypes from 'prop-types';
import PageLoadingTemplate from "./Templates/PageLoadingTemplate";

class PageLoading extends React.Component {
  render() {
    return(
      <PageLoadingTemplate
        loading={this.props.loading}
        itemsCount={this.props.itemsCount}
        variant={this.props.variant}
      />
    )
  }
}

PageLoading.propTypes = {
  loading: PropTypes.bool,
  itemsCount: PropTypes.number,
  variant: PropTypes.string
};

export default PageLoading;