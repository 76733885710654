import { SuperAction } from "../../asyncReducers/SuperAction";
import ClientServices from "../../../api/ClientServices";

export class onStartRedirect extends SuperAction {}
export function startRedirect(path, saveHistory = false) {
  if (saveHistory) {
    if (ClientServices.isBrowser()){
      const window = ClientServices.getWindow();
      console.log('HISTORY');
      console.log(path);
      console.log(window.history.pushState("1", "2", path));
    }
  }
  return new onStartRedirect({ path });
}

export class onFinishedRedirect extends SuperAction {}
export function redirectFinished() {
  return new onFinishedRedirect();
}
