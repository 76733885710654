import { SuperAction } from "../SuperAction";
import {errorNotice} from "../../reducers/notices/action";

export class LoadingUserInfo extends SuperAction {}
export class LoadedUserInfo extends SuperAction {}
export class FailureLoadingUserInfo extends SuperAction {}

export function loadUserInfo(){
  return SuperAction.unifyAction(
    [ LoadingUserInfo, LoadedUserInfo, FailureLoadingUserInfo ],
    {
      url: '/user/info',
      data: {}
    }
  );
}

export class LoadingCallOrder extends SuperAction {}
export class LoadedCallOrder extends SuperAction {}
export class FailureLoadingCallOrder extends SuperAction {}

export function callOrder(type, isClient) {
  if (!isClient && type === 'urist') {
    return errorNotice('Ошибка', 'Доступно для клиентов компании')
  }
  return SuperAction.unifyAction(
    [ LoadingCallOrder, LoadedCallOrder, FailureLoadingCallOrder],
    {
      url: '/user/callOrder',
      data: {type}
    }
  )
}