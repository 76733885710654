import {GetedStartInfo} from "../../asyncReducers/authForm/actions";

const DefaultState = {
  taskTypeList: [
    { id: 1, name: 'Ответ на претензию', sort: 0 },
    { id: 2, name: 'Решение суда', sort: 2},
    { id: 3, name: 'Исковое заявление', sort: 3 },
    { id: 4, name: 'Судебная повестка', sort: 4 },
    { id: 5, name: 'Судебное определение', sort: 5 },
    { id: 6, name: 'Судебный приказ', sort: 6 },
    { id: 7, name: 'Досудебная претензия', sort: 7 },
    { id: 8, name: 'Уведомление о переуступке долга', sort: 8 },
    { id: 9, name: 'Требование об оплате', sort: 9 },
    { id: 10, name: 'Уведомление с целью запугивания', sort: 10 },
    { id: 11, name: 'Ответ на запрос', sort: 1 },
    { id: 12, name: 'Постановление ФССП', sort: 12 },
    { id: 13, name: 'Информация о взысканиях', sort: 13 },
    { id: 14, name: 'Детализация звонков', sort: 14 },
    { id: 15, name: 'SMS с целью запугивания', sort: 11 },
    { id: 16, name: 'Аппеляционная жалоба', sort: 15 },
    { id: 17, name: 'Аппеляционное определение', sort: 16 },
  ]
}

export default (state = DefaultState, action = {}) => {
  switch(action.type) {
    case GetedStartInfo.type:
      return {
        ...state,
        clientDocumentCategory: action.payload.clientDocumentCategory
      }
    default:
      return state;
  }
}