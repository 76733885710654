import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import EventsPageTemplate from "./Templates/EventsPageTemplate";

class Events extends React.Component {
  render(){
    return(
      <EventsPageTemplate
        documents={this.props.documents}
        taskType={this.props.taskType}
      />
    )
  }
}

EventsPageTemplate.propTypes = {
  documents: PropTypes.array
}

export default connect(
  _state => {
    const state = {
      documents: [],
      taskType: []
    }
    if (_state.auth.info) {
      state.documents = _state.auth.info.documents;
    }
    state.taskType = _state.DefaultList.taskTypeList;
    return state;
  },
  dispatcher => bindActionCreators({}, dispatcher)
)(Events);
