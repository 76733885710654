import {
  FailureGetingStartInfo,
  finishAuth,
  GetedStartInfo,
  GetingStartInfo
} from "../../asyncReducers/authForm/actions";
import { registeredUser } from "../../asyncReducers/registerForm/actions";
import {OnAutoAuth, onChangeAccount, onLogout, OnSetDefaultState} from "../../reducers/auth/actions";
import { StartRender } from "../../reducers/application/actions";
import { defaultState } from "../../index";

export default (state = {}, action = {}) => {
  switch (action.type) {
    case finishAuth.type:
      if (action.payload.error === null && action.payload.token) {
        return {
          ...state,
          isAuth: true,
          token: action.payload.token,
          id: action.payload.user.id,
          loadingInfo: false,
          info: null
        };
      }
      return state;
    case registeredUser.type:
      if (action.payload.error === null && action.payload.token) {
        return {
          ...state,
          isAuth: true,
          token: action.payload.token,
          id: action.payload.user.id
        };
      }
      return state;
    case onLogout.type:
      return {
        ...state,
        isAuth: false,
        token: false,
        id: undefined
      };
      return state;
    case GetingStartInfo.type:
      return {
        ...state,
        loadingInfo: true
      }
    case GetedStartInfo.type:
      return {
        ...state,
        loadingInfo: false,
        info: action.payload.client
      }
    case FailureGetingStartInfo.type:
      return {
        ...state,
        loadingInfo: false
      }
    default:
      return state;
  }
};
