import Storage from "./Storage";
import Const from "./Const";

class UserStorage extends Storage {
  static remove(){
    Storage.remove(Const.userId);
    Storage.remove(Const.jwtToken);
  }

  /**
   * Запись информации в хранилища
   * @param info - записываемый объект
   * info.name - имя
   * info.userId - идентитфкатор пользователя
   */
  static set(info) {
    if (typeof info.id !== 'undefined') {
      Storage.set(Const.userId, info.id);
    }
    if (typeof info.token !== 'undefined') {
      Storage.set(Const.jwtToken, info.token);
    }
  }

  static get() {
    const userId = Storage.get(Const.userId, 0);
    const token = Storage.get(Const.jwtToken, null);

    return{
      userId,
      token
    }
  }
}

export default UserStorage;