import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Table} from "react-bootstrap";
import Payments from "../Payments";
import PaymentsItemTemplate from "./PaymentsItemTemplate";
import PaymentsGrapthicItem from "./PaymentsGrapthicItem";

class PaymentsPageTemplate extends Component {
  getGraphick(client) {
    if (
      !client.summ
      || !client.summFull
      || !client.monthPaymentValue
    ) {
      return null;
    }
    let planPayments = [];
    let balance = client.summ;
    let dateSale = new Date(client.dateSale);
    const countPayments = client.summFull / client.monthPaymentValue

    for (let j = 0; j < countPayments && j < 30; j++) {
      let date = new Date();
      if ((client.monthPaymentDate) && (client.dateSale)) {
        date.setDate(client.monthPaymentDate);
        date.setFullYear(dateSale.getFullYear());
        date.setMonth(dateSale.getMonth() + j + 1);
      } else {
        date = null
      }

      if (balance < client.monthPaymentValue) {
        planPayments[j] = {
          step: j + 1,
          value: balance !== 0 ? balance + ' / ' + client.monthPaymentValue : client.monthPaymentValue,
          paymentDate: date,
          isFinished: balance >= client.monthPaymentValue,
        };
        balance = 0;
      } else {
        planPayments[j] = {
          step: j + 1,
          value: client.monthPaymentValue,
          paymentDate: date,
          isFinished: balance >= client.monthPaymentValue,
        };
        balance = balance - client.monthPaymentValue;
      }
    }

    return (
      <Row>
        <Col xl={'12'} lg={'12'} md={'12'} sm={'12'}>
          {
            client.state !== 0?
              planPayments.map(planPayment => {
                return (
                  <PaymentsGrapthicItem
                    step={planPayment.step}
                    desc={planPayment.value}
                    date={planPayment.paymentDate}
                    isFinished={planPayment.isFinished}
                  />
                )
              })
              :
              null
          }
        </Col>
      </Row>
    )
  }
  render() {
    return (
      <Fragment>
        <Row>
          <Col sm={12}>
            <h1>Оплата</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={9} sm={12}>
            <Table responsive>
              <thead>
              <tr>
                <th>Номер</th>
                <th>Дата создания</th>
                <th>Указанная сумма</th>
                <th>Ссылка для оплаты</th>
                <th>Подтверждено</th>
                <th>Дата</th>
              </tr>
              </thead>
              <tbody>
              {
                this.props.payments.map(payment => {
                  return <PaymentsItemTemplate payment={payment}/>
                })
              }
              </tbody>
            </Table>
          </Col>
          <Col lg={3} sm={12}>
            {this.getGraphick(this.props.client)}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

PaymentsPageTemplate.propTypes = {
  payments: PropTypes.array,
  client: PropTypes.object
}

export default PaymentsPageTemplate;