import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import ProcessPageTemplate from "./Templates/ProcessPageTemplate";

class Process extends React.Component {
  render(){
    console.log(this.props);
    return(
      <ProcessPageTemplate
        documents={this.props.documents}
        documentCategory={this.props.documentCategory}
        taskTypes={this.props.taskTypes}
      />
    )
  }
}
ProcessPageTemplate.defaultProps = {
  documents: [],
  documentCategory: [],
  taskTypes: []
}
ProcessPageTemplate.propTypes = {
  documents: PropTypes.array,
  documentCategory: PropTypes.array,
  taskTypes: PropTypes.array
}

export default connect(
  _state => {
    const state = {};

    if (_state.auth.info) {
      state.documents = _state.auth.info.documents;
    }

    if (_state.DefaultList.clientDocumentCategory) {
      state.documentCategory = _state.DefaultList.clientDocumentCategory;
    }
    state.taskTypes = _state.taskTypesList;

    return state;
  },
  dispatcher => bindActionCreators({}, dispatcher)
)(Process);
