import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  QuizPopupName
} from "../../Services/Popup/PopupNames";
import Quiz from "../../Services/Popup/Quiz";
import { injectObjectAsyncReducer } from "../../../store";
import PopupReducer from '../../../store/asyncReducers/Popup';
import { closePopup, clickClosePopup } from "../../../store/asyncReducers/Popup/actions";

class Popup extends React.Component {
  constructor(){
    super();
    injectObjectAsyncReducer(PopupReducer);
  }

  handleClosePopup = () => {
    this.props.closePopup();
  };

  handleClickClosePopup = () => {
    this.props.clickClosePopup();
  }

  render() {
    switch (this.props.name) {
      case QuizPopupName:
        return <Quiz onClickClosePopup={this.handleClickClosePopup}/>
      default:
        return null;
    }
  }
}


Popup.propTypes = {
  name: PropTypes.string,
  data: PropTypes.object,
  // functions
  closePopup: PropTypes.func,
  clickClosePopup: PropTypes.func
};

export default connect(
  state => {
    const popup = state[PopupReducer.name];
    if (popup) {
      return {
        name: popup.name,
        data: popup.data
      }
    }
    return {
      name: '',
      data: {}
    }
  },
  dispatcher => bindActionCreators({ closePopup, clickClosePopup }, dispatcher)
)(Popup);
