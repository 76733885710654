import Request from '../../../api/Request';
import {errorNotice, someError, successNotice} from "../../reducers/notices/action";
import {SuperAction} from "../SuperAction";

export class changeInputValueRegisterForm extends SuperAction {}

export function changeInputValue(name, value) {
  return new changeInputValueRegisterForm({ name, value });
}
export class registeringUser extends SuperAction {}
export class registeredUser extends SuperAction {}
export class failureRegisteredUser extends SuperAction {}

export function registerUser(login, password, confirmPassword, additionalInfo = {}) {
  return dispatch => {
    dispatch(new registeringUser());
    return (new Request()).post('/auth/register', {
      login,
      password,
      confirmPassword,
      ...additionalInfo
    })
        .then(result => {
          if (result.success) {
            dispatch(successNotice('Уведомление', 'Регистрация успешно завершена'));
            return dispatch(new registeredUser(result));
          }
          dispatch(errorNotice('Ошибка регистрации', result.error.message));
          return dispatch(new failureRegisteredUser(result.error));
        })
        .catch(err => {
          console.error(err);
          dispatch(someError());
          return dispatch(new failureRegisteredUser({}));
        });
  };
}
