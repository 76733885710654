import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Root from './ContentMain/Root';
import style from './MainTemplate.module.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

import Services from "../../Components/Services";
import { getStartInfo } from "../../store/asyncReducers/authForm/actions";
import PageLoading from "../../Components/Loading/PageLoading";
import ForClient from "./ContentMain/ForClient/Template";


class MainTemplate extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.isAuth) {
      this.props.getStartInfo();
    }

  }

  getRouter = () => {
    if (!this.props.isAuth) {
      return <Root/>;
    }
    if (this.props.loading || !this.props.info) {
      return <PageLoading loading={true}/>
    }
    return <ForClient/>;
  }
  render () {
    return (
      <div id="mainTemplate">
        <Services/>
        <header id="header">
          <Header/>
        </header>

        <div id="content">
          <div id="layout" className={style.layout}>
            <div id="root" className={style.root}>
              { this.getRouter() }
            </div>
            <Footer/>
          </div>
        </div>

      </div>
    );
  }
}

MainTemplate.propTypes = {
  isAuth: PropTypes.bool,
  loading: PropTypes.bool,
  // functions
  getStartInfo: PropTypes.func
}

export default connect(
  _state => {
    return {
      isAuth: _state.auth.isAuth,
      loading: _state.auth.loadingInfo,
      info: _state.auth.info
    }
  },
  dispatch => bindActionCreators({ getStartInfo }, dispatch)
)(MainTemplate);
