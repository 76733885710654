import React from 'react';
import style from './DocumentiPravila.module.css';
import mainStyle from '../../../Templates/MainTemplate/MainTemplate.module.css';

class DocumentiPravilaTemplate extends React.Component {

    render() {
        return (
            <div className={mainStyle.page}>
                <div className={mainStyle.centered}>
                    <h1 className={style.h1}>
                        Соглашение об условиях пользования сайтом
                    </h1>
                    <div className={style.dogovor}>
                        <div className={style.inDogovor}>
                            Администрация Сайта предоставляет Вам доступ к использованию Сайта <a href="https://office.bankrotstvo-fiz-lic.ru">https://office.bankrotstvo-fiz-lic.ru</a> и его функционала на условиях, являющихся предметом настоящих Правил пользования Сайтом <a href="https://office.bankrotstvo-fiz-lic.ru">https://office.bankrotstvo-fiz-lic.ru</a>. В этой связи Вам необходимо внимательно ознакомиться с условиями настоящих Правил, которые рассматриваются Администрацией Сайта как публичная оферта в соответствии со ст. 437 Гражданского кодекса Российской Федерации.
                            <br/><br/>
                            В случае возникновения проблем (ошибки в работе сайта, проблемы с проведением оплаты) пользователи вправе направлять свои обращения на электронную почту aleksey-kuvaev@mail.ru, либо по телефону +7 (922) 960 08 40.
                            <br/><br/>
                            Эта торговая площадка (далее – «Сайт») является системой покупки фирменной, брендированной продукции для партнеров компании Полезный Юрист (далее – «bankrotstvo-fiz-lic»).
                            <br/><br/>
                            Настоящее Соглашение об условиях пользования сайтом (далее – «Правила» или «Соглашение») применяются к любому использованию Сайта и к любым сообщениям, электронным письмам, любой размещаемой информации с использованием Сайта или схожих сервисов, обслуживаемых bankrotstvo-fiz-lic (далее – «Сообщения»).
                            <br/><br/>
                            Используя Сайт, Вы соглашаетесь с настоящими Правилами и принимаете их условия.
                            <br/><br/>
                            Если Вы не принимаете настоящие Правила, Вы не можете пользоваться Сайтом и не должны оставлять информацию на Сайте.
                            <br/><br/>
                            bankrotstvo-fiz-lic может внести изменения в настоящие Правила в любое время и эти изменения вступают в силу немедленно после размещения по адресу <a href="https://office.bankrotstvo-fiz-lic.ru/documenti/pravila">https://office.bankrotstvo-fiz-lic.ru/documenti/pravila</a>
                            <br/><br/>
                            Используя Сайт, Вы подтверждаете, что у Вас есть необходимое оборудование, программное обеспечение и возможность пользоваться Сайтом, электронная почта и доступ в Интернет. Вы понимаете и соглашаетесь, что bankrotstvo-fiz-lic не несет ответственности за то, как Сайт будет работать с Вашей системой.
                            <div className={style.h2}>
                                Пользование Сайтом
                            </div>
                            Вы можете пользоваться Сайтом исключительно в личных некоммерческих целях.
                            <br/><br/>
                            Вы не можете передавать свое право пользования Сайтом другим лицам без предварительного письменного согласия bankrotstvo-fiz-lic.
                            <br/><br/>
                            Соответственно Вы обязуетесь не:
                            <br/><br/>
                            использовать Сайт для любых коммерческих и бизнес целей;
                            <br/><br/>
                            Вы обязуетесь не применять при пользовании Сайтом программное обеспечение, предназначенное для автоматизации процесса обработки информации, а также иное не одобренное bankrotstvo-fiz-lic программное обеспечение, за исключением Интернет-браузеров.
                            <div className={style.h2}>
                                Авторские права, права на товарные знаки и другие объекты интеллектуальной собственности
                            </div>
                            Все права программное обеспечение, дизайн, базы данных, патенты и любые права на изобретения, ноу-хау, коммерческие наименования, секреты производства, товарные знаки и обозначения товаров и услуг (как зарегистрированные, так и незарегистрированные) и любые их части (далее – «Права на интеллектуальную собственность»), размещенные на Сайте, принадлежат bankrotstvo-fiz-lic или используются bankrotstvo-fiz-lic на основании соглашений с правообладателями, если не указывается иное. Все указанные права закреплены за bankrotstvo-fiz-lic.
                            <br/><br/>
                            Вы не имеете права и обязуетесь не:
                            <br/><br/>
                            взламывать или пытаться взламывать, распространять, изменять, передавать Сайт или его части, а также информацию, содержащуюся на Сайте для любых целей, кроме предусмотренных Правилами;
                            <br/><br/>
                            использовать указанные объекты интеллектуальной собственности в публичных или коммерческих целях без предварительного письменного согласия bankrotstvo-fiz-lic;
                            <br/><br/>
                            демонстрировать, публиковать, копировать, воспроизводить, печатать или иным образом использовать Сайт или содержащуюся на нем информацию в интересах третьих лиц или на других сайтах без предварительного письменного согласия bankrotstvo-fiz-lic;
                            <br/><br/>
                            обрабатывать или иначе использовать информацию, содержащуюся на Сайте с незаконными, аморальными или нечестными целями;
                            <br/><br/>
                            удалять любые знаки о Правах на интеллектуальную собственность, содержащиеся в материалах на Сайте.
                            <div className={style.h2}>
                                Сайт
                            </div>
                            Сайты третьих лиц, на которые содержатся ссылки на Сайте, не проверялись и не отбирались bankrotstvo-fiz-lic. Доступ на такие сайты третьих лиц и их использование Вы осуществляете по своему усмотрению и на свой риск.
                            <br/><br/>
                            Логин и пароль предоставляются Вам для личного доступа на Сайт и не должны разглашаться или передаваться третьим лицам без предварительного письменного согласия bankrotstvo-fiz-lic.
                            <br/><br/>
                            В случае если Вы считаете, что Ваш пароль стал известен другим лицам, Вы должны немедленно сообщить об этом bankrotstvo-fiz-lic, так как за неправомерные действия, совершенные с использованием Вашего пароля несете ответственность Вы, а не bankrotstvo-fiz-lic.
                            <br/><br/>
                            bankrotstvo-fiz-lic и Сайт могут автоматически генерировать электронные письма для информирования. Используя Сайт, Вы соглашаетесь, что Вы будете получать эти сообщения и что эти сообщения, которые Вы получаете, могут быть типовыми.
                            <br/><br/>
                            bankrotstvo-fiz-lic имеет право уступить права, предоставленные Вами, третьим лицам.
                            <div className={style.h2}>
                                Ответственность
                            </div>
                            bankrotstvo-fiz-lic предоставляет и обслуживает Сайт для продажи фирменно, брендированной продукции компании "Полезный Юрист" для партнеров компании.
                            <br/><br/>
                            bankrotstvo-fiz-lic ответственен только за предоставление услуг с разумной заботой и не дает других гарантий относительно Сайта;
                            <br/><br/>
                            В соответствии с вышесказанным, bankrotstvo-fiz-lic не несет ответственности за убытки и ущерб любого рода, включая без ограничения прямой ущерб, упущенную выгоду, даже если таковые явились следствием обстоятельств, о которых Вы уведомляли bankrotstvo-fiz-lic, и bankrotstvo-fiz-lic не будет обязан выплачивать компенсации в том числе, но, не ограничиваясь, в случаях, связанных с:
                            <br/><br/>
                            Любой неточностью информации на Сайте, а также перерывами и задержками в оказании услуг;
                            <br/><br/>
                            Нарушением любым лицом любых прав на объекты интеллектуальной собственности третьих лиц, связанным с использованием Сайта;
                            <br/><br/>
                            Любыми убытками, причиненными воздействием вирусов или иного вредоносного программного обеспечения, которое может поражать программное обеспечение и компьютер пользователя, повреждать данные и иные объекты, если это явилось следствием доступа на Сайт, использования Сайта или получения сообщений электронной почты от bankrotstvo-fiz-lic и ее партнеров;
                            <br/><br/>
                            Доступностью, качеством, содержанием и природой других сайтов в сети Интернет, которые принадлежат и управляются третьими лицами («Сторонние сайты»), ссылки на которые есть на Сайте, а также любыми действиями с Сторонними сайтами (касающимися файлов «куки», персональных данных, конфиденциальной информации, покупки доменных имен и т.д.). По таким вопросам Вам следует связываться с администраторами Сторонних сайтов;
                            <br/><br/>
                            Доступностью, использованием и/или раскрытием любой информации или Сообщений или любых материалов, предоставленных Вами bankrotstvo-fiz-lic неуполномоченными третьими лицами;
                            <br/><br/>
                            Всеми гарантиями и обязательствами, на которые распространяется настоящее условие.
                            <br/><br/>
                            bankrotstvo-fiz-lic не гарантирует, что работа Сайта будет непрерывной и безошибочной.
                            <div className={style.h2}>
                                Расторжение
                            </div>
                            bankrotstvo-fiz-lic имеет право расторгнуть настоящее Соглашение и немедленно прекратить доступ к Сайту в случае нарушения Вами любого условия настоящих Правил. Также соглашение может быть немедленно расторгнуто любой стороной по ее усмотрению.
                            <br/><br/>
                            Расторжение договора не повлияет на права, полученные Сторонами в период действия Соглашения.
                            <br/><br/>
                            bankrotstvo-fiz-lic не будет нести ответственность в случае невозможность выполнить обязанности по Соглашению, если такая невозможность наступила в результате непредвиденных обстоятельств, находящихся вне разумного контроля Сторон, включая в том числе неполадки в работе сети Интернет, средств связи, пожары, боевые действия, наводнения или иные стихийные бедствия.
                            <div className={style.h2}>
                                Общие условия
                            </div>
                            В случае если любые положения настоящих Правил окажутся не подлежащими применению, такие положения будут применяться в возможных пределах, наиболее соответствующим намерениям Сторон по мнению bankrotstvo-fiz-lic образом, а остальные положения Правил сохранят свою силу.
                            <br/><br/>
                            Настоящие Правила составляют полное соглашение между Вами и bankrotstvo-fiz-lic и будут применяться вместо любых других правил.
                            <br/><br/>
                            Настоящие правила регулируются законодательством Российской Федерации. Вопросы, не урегулированные в настоящих правилах, регулируются законодательством Российской Федерации.
                            <br/><br/>
                            В случае возникновения споров или разногласий, связанных с соблюдением настоящих Правил, Вы и bankrotstvo-fiz-lic приложите все усилия, чтобы решить их с помощью переговоров. Споры, которые не были разрешены в ходе переговоров, должны разрешаться в соответствии с процедурами, установленными действующим российским законодательством.
                            <br/><br/>
                            В отдельных случаях bankrotstvo-fiz-lic может оказывать Вам услуги с условием их оплаты после заказа. В этом случае Вы должны оплатить эти услуги в течение 3 дней после заказа услуги. При этом денежные средства, поступающие на Ваш счет на Сайте, будут рассматриваться как оплата таких услуг и соответственно списываться со счета до полной оплаты услуг. В случае если такие услуги не будут оплачены в срок, bankrotstvo-fiz-lic вправе прекратить Ваш доступ к услугам Сайта (как в целом, так и к отдельным услугам) до полной оплаты заказанных Вами услуг.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocumentiPravilaTemplate;
