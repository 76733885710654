import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AuthFormTemplate from './Templates/AuthFormTemplate';
import { injectObjectAsyncReducer } from "../../store";
import authFormReducer from '../../store/asyncReducers/authForm';
import {
  changeInputValue,
  auth,
  onResetNeedRedirect,
  onChangeViewPassword
} from "../../store/asyncReducers/authForm/actions";
import { setPageInfo } from "../../store/actions/PageInfo";
import { startRedirect } from "../../store/reducers/route/actions";

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    props.setPageInfo({
      title: 'Вход в личный кабинет | Полезный юрист',
      description: 'Вход в личный кабинет | Полезный юрист'
    });
  }
  componentDidMount() {
    injectObjectAsyncReducer(authFormReducer);
    console.log(this.props.isAuth);
  }

  componentDidUpdate() {
    if (this.props.isNeedRedirect) {
      this.props.onResetNeedRedirect();
      this.props.startRedirect('/lk/consulting');
    }
    if (this.props.isAuth) {
      this.props.startRedirect('/lk/consulting');
    }
  }

  handleAuth = () => {
    this.props.auth(
        this.props.login,
        this.props.password
    );
  } ;

  handleChangeValue = (name, value) => {
    this.props.changeInputValue(name, value);
  };

  render() {
    return (
        <AuthFormTemplate
            login={this.props.login}
            password={this.props.password}
            isViewPassword={this.props.isViewPassword}
            onChangeInputValue={this.handleChangeValue}
            onClickAuth={this.handleAuth}
            onChangeViewPassword={this.props.onChangeViewPassword}
        />
    );
  }
}

AuthForm.propTypes = {
  login: PropTypes.string,
  password: PropTypes.string,
  isAuth: PropTypes.bool,
  isViewPassword: PropTypes.bool,
  // functions
  isNeedRedirect: PropTypes.func,
  changeInputValue: PropTypes.func,
  setPageInfo: PropTypes.func,
  auth: PropTypes.func,
  onResetNeedRedirect: PropTypes.func,
  startRedirect: PropTypes.func,
  onChangeViewPassword: PropTypes.func
};

export default connect(
    state => {
      const stateAuthForm = state[authFormReducer.name];
      if (stateAuthForm) {
        return {
          isAuth: state.auth.isAuth,
          login: stateAuthForm.login,
          password: stateAuthForm.password,
          isNeedRedirect: stateAuthForm.isNeedRedirect,
          isViewPassword: stateAuthForm.isViewPassword
        };
      }
      return {
        isAuth: state.auth.isAuth,
        login: '',
        password: '',
        isNeedRedirect: false,
        isViewPassword: false
      };
    },
    dispatcher => bindActionCreators({
      changeInputValue,
      setPageInfo,
      auth,
      onResetNeedRedirect,
      startRedirect,
      onChangeViewPassword
    }, dispatcher)
)(AuthForm);
