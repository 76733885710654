import React from 'react';
import PropTypes from 'prop-types';
import style from './Quiz.module.css';

class QuizThanksTemplate extends React.Component{
    render(){
        return(
            <div className={style.thanks}>
                <div className={style.thanksH1Title}>
                    Спасибо за заявку!
                </div>
                <div className={style.thanksText}>
                    Ожидайте звонка с номера +7(995)446-96-86 или +7(995)309-27-54.
                </div>
                <div className={style.contactButton} onClick={this.props.onClickClose}>
                    Закрыть
                </div>
            </div>
        );
    };
}

QuizThanksTemplate.propTypes = {
    step: PropTypes.string,
    // functions
    onClickClose: PropTypes.func
};

export default QuizThanksTemplate;