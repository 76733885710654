import Const from './Const';
import JsCoockie from 'js-cookie';

class Cookie extends Const{
  static setItem(name, value, expires = 365) {
    JsCoockie.set(name, value, { expires });
    // if (ClientServices.isBrowser()) {
    //
    // }
  }
  static getItem(name) {
    JsCoockie.get(name);
    // if (ClientServices.isBrowser()) {
    //
    // }
  }
  static removeItem(name) {
    JsCoockie.remove(name);
    // if (ClientServices.isBrowser()) {
    //
    // }
  }
}

export default Cookie;
