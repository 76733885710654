import {
  createdDogovor, creatingDogovor, FailureLoadingClientInfo, LoadedClientInfo, LoadingClientInfo,
  onChangeFieldDogovor, onFinishSignDogovor, onNextStepDogovor,
  onStartSignDogovor
} from "./actions";

class Dogovor {
  static DefaultState = {
    step: 0,
    form: {
      bSurname: '',
      bName: '',
      bSecondname: '',
      surname: '',
      name: '',
      secondname: '',
      birthday: '',
      phone: '',
      passportNumber: '',
      passportSeria: '',
      passportIssuedBy: '',
      whenReceivedPassport: '',
      address: '',
      installmentPeriod: 1
    },
    dogovor: {
      paths: {
        downloadPath: '',
        thumbnails: []
      },
      dogovorId: false
    }
  };

  static reducer = (state = Dogovor.DefaultState, action = {type: '', payload: {}}) => {
    switch (action.type) {
      case onChangeFieldDogovor.type:
        return {
          ...state,
          form: {
            ...state.form,
            [action.payload.name]: action.payload.value
          }
        }
      case creatingDogovor.type:
        return {
          ...state,
          loading: true
        }
      case createdDogovor.type:
        return {
          ...state,
          step: state.step + 1,
          dogovor: {
            ...state.dogovor,
            ...action.payload
          },
          loading: false
        }
      case onStartSignDogovor.type:
        return {
          ...state,
          step: state.step + 1
        }
      case onFinishSignDogovor.type:
        return {
          ...state,
          step: state.step + 1
        }
      case onNextStepDogovor.type: {
        return {
          ...state,
          step: state.step + 1
        }
      }
      case LoadingClientInfo.type: {
        return {
          ...state,
          loading: true
        }
      }
      case LoadedClientInfo.type: {
        return {
          ...state,
          loading: false,
          form: {
            ...state.form,
            bSurname: action.payload.client.surname,
            bName: action.payload.client.name,
            bSecondname: action.payload.client.secondname,
            surname: action.payload.client.surname,
            name: action.payload.client.name,
            secondname: action.payload.client.secondname
          }
        }
      }
      case FailureLoadingClientInfo.type: {
        return {
          ...state,
          loading: false
        }
      }
      default:
        return state;
    }
  }
}

export default Dogovor;
