import {SuperAction} from "../SuperAction";
import UTM from '../../../api/UTM';
import Metrika from "../../../api/Metrika";
import Storage from "../../../api/Storage";
import Const from "../../../api/Const";
import {errorNotice} from "../../reducers/notices/action";
export class OnChangeAnswer extends SuperAction {};

export function changeAnswer(question, answer) {
  Metrika.goal(`quiz_question_${question.id}`, false);
  return new OnChangeAnswer({ question, answer});
}

export class OnChangeQuizStep extends SuperAction {}
export function changeQuizStep(direction) {
  return new OnChangeQuizStep({ direction });
}

export class SendingQuiz extends SuperAction {}
export class SendedQuiz extends SuperAction {}
export class FailureSendingQuiz extends SuperAction {}

export function sendQuiz(quiz, contact) {
  if (!contact.phone) {
    return dispatch => {
      return dispatch(errorNotice('Ошибка заполнения', 'Пожалуйста, введите контактный номер телефона'));
    }
  }
  Metrika.goal('quiz_complete');
  const utm = UTM.utm;
  Storage.set(Const.sendQuiz, 1);
  return SuperAction.unifyAction(
    [ SendingQuiz, SendedQuiz, FailureSendingQuiz ],
    {
      url: '/user/quiz',
      data: { quiz, contact, utm }
    }
  );
}

export class OnChangeQuizContactFormField extends SuperAction {}

export function changeQuizContactFormField(name, value) {
  return new OnChangeQuizContactFormField({ name, value });
}