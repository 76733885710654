class ClientServices {
  static isBrowser() {
    return process.env.BROWSER;
  }

  static getWindow() {
    if (this.isBrowser()) {
      return window;
    }
    return false;
  }

  static scrollTo(x = 0, y = 0) {
    if (ClientServices.isBrowser()) {
      window.scrollTo(x, y);
    }
  }

  static setLocation(location) {
    if (this.isBrowser()) {
      this.getWindow().location = location;
    }
  }

  static getLocationPathName() {
    if (this.isBrowser()) {
      return this.getWindow().location.pathname;
    }
    return '';
  }

  static getInnerHeight(defaultValue = 100) {
    if (this.isBrowser()) {
      return this.getWindow().innerHeight;
    }
    return defaultValue;
  }

  static getInnerWidth(defaultValue = 100) {
    if (this.isBrowser()) {
      return this.getWindow().innerWidth;
    }
    return defaultValue;
  }

  static addEventListener(eventName, func) {
    if (this.isBrowser() && typeof func === 'function') {
      this.getWindow().addEventListener(eventName, func);
    }
  }

  static removeEventListener(eventName, func) {
    if (this.isBrowser() && typeof func !== 'undefined' && typeof func === 'function') {
      this.getWindow().removeEventListener(eventName, func);
    }
  }
  static getLocalStorage() {
    if (this.isBrowser()) {
      return localStorage;
    }
    return false;
  }

  // ---------- methods for document ---------------

  static getDocument() {
    if (this.isBrowser()) {
      return document;
    }
    return false;
  }

  static hiddenPageScroll() {
    if (this.isBrowser()) {
      this.getDocument().body.style.overflow = 'hidden';
    }
  }

  static showPageScroll() {
    if (this.isBrowser()) {
      this.getDocument().body.style.overflow = 'auto';
    }
  }

  static getElementById(id) {
    if (this.isBrowser()) {
      return this.getDocument().getElementById(id);
    }
    return false;
  }
}


export default ClientServices;
