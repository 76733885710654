import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';

class NoticesItemTemplate extends React.Component {
  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.props.onDeleteNotice(this.props.item.id);
    }, 5000);
  }



  handleClickNotice = () => {
    clearTimeout(this.timeoutId);
    this.props.onDeleteNotice(this.props.item.id);
  }

  timeoutId;

  // this.props.item.type - success или error. смотри NoticeTypes в импорте
  render() {
    return (
      <div className={style.item} onClick={this.handleClickNotice}>
        {
          this.props.item.title ?
            <div className={style.itemTitle}>
              <div>
                {this.props.item.title}
              </div>
            </div>
            :
            null
        }
        <div className={style.itemMessage}>
          <div>
            { this.props.item.message }
          </div>
        </div>
      </div>
    );
  }
}

NoticesItemTemplate.propTypes = {
  item: PropTypes.object,
  // functions
  onDeleteNotice: PropTypes.func
}

export default NoticesItemTemplate;
