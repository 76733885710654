import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPageInfo } from "../../store/actions/PageInfo";
import DogovorFinTemplate from "../DogovorFin/Tempaltes/DogovorFinTemplate";

import { injectObjectAsyncReducer } from "../../store";
import DogovorReducer from '../../store/asyncReducers/Dogovor'
import ConfirmationReducer from '../../store/asyncReducers/Confirmation'
import {
  changeFieldDogovor,
  signDogovor,
  finishDogovor,
  nextStepDogovor
} from "../../store/asyncReducers/Dogovor/actions";
import { changeConfirmationPassword } from "../../store/asyncReducers/Confirmation/actions";
import { createDogovor } from "../../store/asyncReducers/Dogovor/actions";
import { loadClientInfo } from "../../store/asyncReducers/Dogovor/actions";

class DogovorFinFull extends React.Component {
  constructor(props) {
    super(props);
    props.setPageInfo({
      title: 'Договор | Полезный юрист',
      description: 'Договор | Полезный юрист'
    })
  }

  handleCreateDogovor = () => {
    this.props.createDogovor(this.props.form, this.props.price, this.props.type, true, this.props.clientId, this.props.newType);
  }

  componentDidMount() {
    injectObjectAsyncReducer(DogovorReducer);
    injectObjectAsyncReducer(ConfirmationReducer);
    this.props.loadClientInfo(this.props.clientId);
  }

  handleStartSignDogovor = () => {
    this.props.signDogovor(this.props.form.phone, this.props.dogovor.dogovorId, true);
  }

  handleFinishSignDogovor = () => {
    this.props.finishDogovor(this.props.confirmationPassword, true);
  }

  render() {
    return (
      <DogovorFinTemplate
        type={this.props.type}
        step={this.props.step}
        form={this.props.form}
        price={Number(this.props.price)}
        confirmationPassword={this.props.confirmationPassword}
        paths={this.props.dogovor}
        loading={this.props.loading}
        onChangeField={this.props.changeFieldDogovor}
        onCreateDogovor={this.handleCreateDogovor}
        onStartSignDogovor={this.handleStartSignDogovor}
        onFinishSignDogovor={this.handleFinishSignDogovor}
        onChangeConfirmationPassword={this.props.changeConfirmationPassword}
        onNextStep={this.props.nextStepDogovor}
      />
    );
  }
}

DogovorFinFull.propTypes = {
  loading: PropTypes.bool,
  clientId: PropTypes.string,
  price: PropTypes.number,
  state: PropTypes.number,
  newType: PropTypes.bool,
  form: PropTypes.object,
  dogovor: PropTypes.object,
  confirmationPassword: PropTypes.string,
  type: PropTypes.string, // Тип заключаемого договора
  // functions
  setPageInfo: PropTypes.func,
  changeFieldDogovor: PropTypes.func,
  createDogovor: PropTypes.func,
  signDogovor: PropTypes.func,
  finishDogovor: PropTypes.func,
  changeConfirmationPassword: PropTypes.func,
  nextStepDogovor: PropTypes.func,
  loadClientInfo: PropTypes.func
};

DogovorFinFull.defaultProps = {
  dogovor: {
    paths: {
      downloadPath: '',
      thumbnails: []
    },
    dogovorId: false
  }
}

export default connect(
  state => {
    const reducer = state[DogovorReducer.name];
    const confirmationReducer = state[ConfirmationReducer.name];

    let result = {
      ...DogovorReducer.DefaultState,
      confirmationPassword: ''
    };

    if (reducer) {
      result = reducer;
    }

    if (confirmationReducer) {
      result = {
        ...result,
        confirmationPassword: confirmationReducer.form.password
      }
    }

    return  result;
  },
  dispatcher => bindActionCreators({
    setPageInfo,
    changeFieldDogovor,
    createDogovor,
    signDogovor,
    finishDogovor,
    changeConfirmationPassword,
    nextStepDogovor,
    loadClientInfo
  }, dispatcher)
)(DogovorFinFull);
