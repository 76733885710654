import { StartRender } from "./actions";
import {OnSetStatusCode} from "./actions";
import { OnSetDefaultState } from "../auth/actions";

export default (state = {}, action = {}) => {
  switch (action.type) {
    case StartRender.type:
      return state;
    case OnSetDefaultState.type:
      return {
        statusCode: 200
      }
    case OnSetStatusCode.type:
      console.log('reducer set status code');
      return {
        ...state,
        statusCode: action.payload.statusCode
      }
    default:
      return state;
  }
};
