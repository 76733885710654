import React from 'react';
import FooterTemplate from "./Templates/FooterTemplate";
import Metrika from "../../api/Metrika";

class Footer extends React.Component {
  handleClickPhone = () => {
    Metrika.goal('phone');
  }

  render() {
    return (
      <FooterTemplate
        onClickPhone={this.handleClickPhone}
      />
    );
  }
}

export default Footer;
