import React from 'react';
import PropTypes from 'prop-types';
import style from './Quiz.module.css';
import QuizQuestionStepTemplate from "./QuizQuestionStepTemplate";
import QuizContactsTempalte from "./QuizContactsTempalte";
import QuizThanksTemplate from "./QuizThanksTemplate";

class QuizTemplate extends React.Component{
  render(){

    const skidka = 3000;
    const countOfQuestions = 8;

    const { answers, step, onChangeAnswer, onClickClosePopup, questions, contactForm } = this.props;

    const isQuestion = step <= countOfQuestions;
    let question = null;
    let answer = null;
    if (isQuestion) {
      question = questions[step];
      answer = answers[question.id] ? answers[question.id] : {};
    }
    const { name, phone } = contactForm;
    console.log(answers);
    return(

        <div className={style.popupBackground}>
          <div className={style.closePopup} onClick={this.props.onClickClosePopup}/>
          <div className={style.popup}>

            <div className={style.popupTop}>
              <div className={style.left}>
                <div className={style.cupon}>
                  <div className={style.currentCupon}>
                    <div>
                      Ваша скидка:
                    </div>
                    <div>
                      {((skidka / countOfQuestions) * (this.props.step - 1)) > skidka?
                          <span>3 000 руб</span>
                          :
                          <span>{(skidka / countOfQuestions) * (this.props.step - 1)} руб</span>
                      }
                    </div>
                  </div>
                  {isQuestion ?
                      <div className={style.onEveryAnswerCupon}>
                        <div>
                          За каждый ответ:
                        </div>
                        <div>
                          <span>+ {skidka / countOfQuestions} руб</span>
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
                {isQuestion ?
                    <div className={style.step}>
                      Вопрос: <span>{this.props.step}</span> / 8
                    </div>
                    :
                    null
                }
                <div className={style.consultant}>
                  <img src={'https://lk.conversus.pro/managers/3/101.jpg'} />
                </div>
              </div>
              <div className={style.right}>
                {isQuestion?
                    <div>
                      <div className={style.h1TitleAndCloseButton}>
                        <div className={style.h1Title}>
                          Пройдите опрос за 2 минуты и узнайте способы списания Ваших долгов
                        </div>
                        <div className={style.buttonClosePopup} onClick={this.props.onClickClosePopup}>
                          ✕
                        </div>
                      </div>
                      <div className={style.h2Title}>
                        За прохождение опроса получите БЕСПЛАТНУЮ консультацию юриста + скидка 3000 рублей
                      </div>
                    </div>
                    :
                    <div>
                      <div className={style.h1TitleAndCloseButton}>
                        <div className={style.h1Title}>
                        </div>
                        <div className={style.buttonClosePopup} onClick={this.props.onClickClosePopup}>
                          ✕
                        </div>
                      </div>
                    </div>
                }
                {
                  isQuestion ?
                      <QuizQuestionStepTemplate
                          key={step}
                          step={step}
                          currentAnswer={answer}
                          onSelectAnswer={onChangeAnswer}
                          question={question}
                      />
                      :
                      null
                }
                {this.props.step === 9 ?
                    <QuizContactsTempalte
                        answerId={typeof answers['1'] !== 'undefined' ? answers['1'].id : -1}
                        step={this.props.step}
                        onChangeState={this.props.onChangeState}
                        name={name}
                        phone={phone}
                        onChangeField={this.props.onChangeField}
                        onClickSendRequest={this.props.onClickSendRequest}
                    />
                    :
                    null
                }
                {this.props.step === 10 ?
                    <QuizThanksTemplate
                        step={this.props.step}
                        onChangeState={this.props.onChangeState}
                        onClickClose={this.props.onClickClosePopup}
                    />
                    :
                    null
                }
              </div>
            </div>


            <div className={style.popupFooter}>
              <div className={style.rules}>
                Передавая информацию сайту, вы принимаете условия <a href={'/documenti/politika-konfidencialnosti'} target={'_blank'}>Политики конфиденциальности</a>
                <br/>
                Информация не передаётся третьим лицам!
              </div>
              <img src="https://office.bankrotstvo-fiz-lic.ru/src/images/for_main_landing/white-logo.jpg" title="Полезный юрист" alt="Полезный юрист" />
            </div>

          </div>

        </div>
    );
  };
}

QuizTemplate.propTypes = {
  step: PropTypes.number,
  answers: PropTypes.array,
  questions: PropTypes.array,
  contactForm: PropTypes.object,
  // functions
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
  onChangeAnswer: PropTypes.func,
  onClickClosePopup: PropTypes.func,
  onChangeField: PropTypes.func,
  onClickSendRequest: PropTypes.func
};

export default QuizTemplate;