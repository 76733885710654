import Request from '../../../api/Request';
import UserStorage from "../../../api/UserStorage";
import { someError, errorNotice } from "../../reducers/notices/action";
import { SuperAction } from "../SuperAction";
import RequestCrmApi from "../../../api/RequestCrmApi";

class changeInputValueAuthForm extends SuperAction {}

function changeInputValue(name, value) {
  return new changeInputValueAuthForm({ name, value });
}

class startAuth extends SuperAction {}
class finishAuth extends SuperAction {}
class failureAuth extends SuperAction {}

function auth(login, password) {
  return dispatch => {
    dispatch(new startAuth());

    return RequestCrmApi.post('/clients/auth/auth', { login, password })
      .then(result => {
        if (result.error !== null) {
          dispatch(errorNotice("Ошибка авторизации", result.error.message));
          return dispatch(new failureAuth(result.error));
        }
        UserStorage.set({
          token: result.token,
          ...result.user
        });

        dispatch(new finishAuth(result))
        dispatch(getStartInfo());
        return;
      })
      .catch(err => {
        console.log(err);

        dispatch(someError());
        return dispatch(new failureAuth({}));
      });
  };
}

export class GetingStartInfo extends SuperAction {}
export class GetedStartInfo extends SuperAction {}
export class FailureGetingStartInfo extends SuperAction {}

export function getStartInfo() {
  return SuperAction.unifyAction(
    [GetingStartInfo, GetedStartInfo, FailureGetingStartInfo],
    {
      url: '/clients/auth/getStartInfo',
      data: {}
    }
  )
}


class resetNeedRedirect extends SuperAction {}

function onResetNeedRedirect() {
  return new resetNeedRedirect();
}

class changeViewPassword extends SuperAction {}

function onChangeViewPassword() {
  return new changeViewPassword();
}

export {
  changeInputValue,
  changeInputValueAuthForm,
  startAuth,
  finishAuth,
  failureAuth,
  auth,
  resetNeedRedirect,
  onResetNeedRedirect,
  changeViewPassword,
  onChangeViewPassword
};
