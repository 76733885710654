import {
  FailureLoadingUserInfo, LoadedCallOrder,
  LoadedUserInfo,
  LoadingCallOrder,
  LoadingUserInfo
} from "./actions";

class User {
  static DefaultState = {
    info: {
      item: {},
      form: {
        loading: false,
        fields: {
          surname: '',
          name: '',
          secondname: '',
          phone: '',
          email: ''
        }
      },
      loading: false,
      loaded: false
    },
    callOrder: {
      loadingUristCallOrder: false,
      loadingManagerCallOrder: false
    }
  };

  static reducer = (state = User.DefaultState, action = {}) => {
    switch (action.type) {
      case LoadingUserInfo.type:
        return {
          ...state,
          info: {
            ...state.info,
            loading: true
          }
        }
      case LoadedUserInfo.type:
        console.log('-- LOADED USER INFO --');
        console.log(action.payload);
        return {
          ...state,
          info: {
            ...state.info,
            item: action.payload.item,
            loading: false,
            loaded: true
          }
        }
      case FailureLoadingUserInfo.type:
        return {
          ...state,
          info: {
            ...state.info,
            item: {},
            loading: false,
            loaded: false
          }
        }
      case LoadingCallOrder.type:
        return {
          ...state,
          callOrder: {
            ...state.callOrder,
            loadingUristCallOrder: action.payload.type === 'urist',
            loadingManagerCallOrder: action.payload.type === 'manager'
          }
        }
      case LoadedCallOrder.type:
        return {
          ...state,
          info: {
            ...state.info,
            item: {
              ...state.info.item,
              isUristCallOrdered: action.payload.type === 'urist' ? true : state.info.item.isUristCallOrdered,
              isManagerCallOrdered: action.payload.type === 'manager' ? true : state.info.item.isManagerCallOrdered
            }
          },
          callOrder: {
            ...state.callOrder,
            loadingUristCallOrder: action.payload.type === 'urist' ? false : state.callOrder.loadingUristCallOrder,
            loadingManagerCallOrder: action.payload.type === 'manager' ? false : state.callOrder.loadingManagerCallOrder
          }
        }
      default:
        return state;
    }
  };
}

export default User;
