import { actionTypeSetPageInfo } from "./type";
import { StartRender } from "../../reducers/application/actions";

const defaultState = {
  title: '',
  description: ''
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case StartRender.type:
      return defaultState;
    case actionTypeSetPageInfo:
      return {
        title: action.payload.title ? action.payload.title : state.pageInfo.title,
        description: action.payload.description ? action.payload.description : state.pageInfo.description
      };
    default:
      return state;
  }
}