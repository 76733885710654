import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";

class ProcessItemTemplate extends Component {
  render(){
    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={this.props.document.id}>
            {this.props.document.category.name}. {this.props.document.type.name}. { this.props.document.name }
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={this.props.document.id}>
          <Card.Body>
            {
              this.props.document.files.map(file => {
                return (
                  <Row>
                    <Col sm={3}>
                      <a href={`https://crm.zgm-holding.com/files${file.path}`} target={'_blank'}>Скачать файл документа</a>
                    </Col>
                  </Row>
                )
              })
            }
            {
              this.props.document.tasks.map(task => {
                const types = this.props.taskTypes.filter(el => el.id === task.typeId);
                const type = types ? types[0] : null;
                return (
                  <Row>
                    <Col sm={12}>
                      { type ? type.name : '' } { task.content ? task.content : '' }
                      { ' ' }
                      {
                        task.files.map(file => {
                          return (
                            <a href={`https://crm.zgm-holding.com/files${file.path}`} target={'_blank'}>Скачать приложенный документ </a>
                          )
                        })
                      }
                    </Col>
                  </Row>
                );
              })
            }
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

ProcessItemTemplate.propTypes = {
  document: PropTypes.object,
  documentCategory: PropTypes.array,
  taskTypes: PropTypes.array
}

export default ProcessItemTemplate;