import {SuperAction} from "../SuperAction";
import {QuizPopupName} from "../../../Components/Services/Popup/PopupNames";
import Metrika from "../../../api/Metrika";

export class OnShowPopup extends SuperAction {};
export function showPopup(name, data) {
  if (name === QuizPopupName) {
    Metrika.goal('quiz_open', false);
  }
  return new OnShowPopup({ name, data});
}

export class OnClosePopup extends SuperAction {};
export function closePopup(){
  return new OnClosePopup();
}
export class OnClickClosePopup extends SuperAction {};
export function clickClosePopup(){
  return new OnClickClosePopup();
}