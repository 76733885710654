import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Container, Navbar, Nav, Row, Col, NavDropdown } from "react-bootstrap";

class HeaderTemplate extends Component {
  getNavigationContent = () => {
    if (!this.props.isAuth) {
      return null;
    }

    return (
      <Fragment>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" bg="dark" variant="dark">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to={'/'}>Информация</Nav.Link>
            <Nav.Link as={Link} to={'/process'}>Документы</Nav.Link>
            <Nav.Link as={Link} to={'/events'}>События</Nav.Link>
            <Nav.Link as={Link} to={'/payments'}>Оплата</Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown title={`Личный кабинет`} id="nav-item-personal">
              <NavDropdown.Item onClick={this.props.onClickLogout}>Выход</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Fragment>
    )
  }


  render() {
    return (
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Navbar expand="lg" variant="light" bg="light">
              <Container>
                <Navbar.Brand href="/clients">
                  <img alt={''} style={{maxHeight: '50px'}} src="/logo_pu.png"/>
                </Navbar.Brand>
                { this.getNavigationContent() }
              </Container>
            </Navbar>
          </Col>
        </Row>
      </Container>
    );
  }
}

HeaderTemplate.propTypes = {
  isAuth: PropTypes.bool,
  name: PropTypes.string,
  user: PropTypes.object,
  loadedUser: PropTypes.bool,
  onClickPhone: PropTypes.func,
  onClickLogout: PropTypes.func
};

export default HeaderTemplate;
