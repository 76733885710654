import React from 'react';
import MainTemplate from './MainTemplate/MainTemplate';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

class Main extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={"/"} component={MainTemplate}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Main;
