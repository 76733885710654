import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventsItemTemplate from "./EventsItemTemplate";
import { Table } from "react-bootstrap";

class EventsListTemplate extends Component {
  render(){
    let tasksList = [];
    this.props.documents.map(document => {
      tasksList = tasksList.concat(document.tasks.map(task => {
        return {
          ...task,
          document
        }
      }));
      return document;
    });

    tasksList = tasksList.sort((a, b) => {
      if (a.id > b.id) {
        return -1;
      } else if (a.id < b.id) {
        return 1;
      } else {
        return 0;
      }
    });

    return (
      <Table striped bordered hover>
        <thead>
        <th>#</th>
        <th>Задача</th>
        <th>Дата</th>
        <th>Номер документа</th>
        <th>Документ</th>
        <th>Дата документа</th>
        </thead>
        <tbody>
        {
          tasksList.map(task => {
            return <EventsItemTemplate key={task.id} task={task} taskType={this.props.taskType}/>
          })
        }
        </tbody>
      </Table>
    )
  }
}

EventsListTemplate.propTypes = {
  documents: PropTypes.array,
  taskType: PropTypes.array
}

export default EventsListTemplate;