import Request from '../../api/Request';
import {errorNotice, successNotice, someError} from "../../store/reducers/notices/action";
import RequestCrmApi from "../../api/RequestCrmApi";

export class SuperAction {
  static get type() {
    return `${this.name}`
  }

  constructor(payload) {
    return {
      type: this.constructor.type,
      payload
    }
  }

  static unifyAction(startActions, requestInfo, successNoticeInfo = null) {
    return dispatch => {
      const [ loading, loaded, failureLoading ] = startActions;
      const { url, data } = requestInfo;

      console.log(requestInfo);

      dispatch(new loading(requestInfo.data));
      RequestCrmApi.post(url, data)
          .then(response => {
            if (response.error !== null) {
              dispatch(errorNotice('Ошибка', response.error.message));
              return dispatch( new failureLoading(response));
            }

            if (successNoticeInfo !== null) {
              const { title, message } = successNoticeInfo;
              dispatch(successNotice(title, message));
            }

            return dispatch(new loaded(response));
          })
          .catch(err => {
            console.error(err);
            dispatch(someError());
            return dispatch(new failureLoading);
          })
    }
  }
}

export class Error{
  static error = {
    code: '',
    error: ''
  }
}
