import React from 'react';
import style from './DocumentiPolitikaKonfidencialnosti.module.css';
import mainStyle from '../../../Templates/MainTemplate/MainTemplate.module.css';

class DocumentiPolitikaKonfidencialnostiTemplate extends React.Component {

    render() {
        return (
            <div className={mainStyle.page}>
                <div className={mainStyle.centered}>
                    <h1 className={style.h1}>
                        Политика конфиденциальности
                    </h1>
                    <div className={style.dogovor}>
                        <div className={style.inDogovor}>
                            <div className={style.h2}>
                                1. Общие положения
                            </div>
                            1.1. Целью настоящих Правил является обеспечение надлежащей защиты информации о пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.
                            <br/><br/>
                            1.2. Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях Сайта, регулируются настоящими Правилами, иными официальными документами Администрации Сайта и действующим законодательством Российской Федерации.
                            <br/><br/>
                            1.3. Действующая редакция Правил, являющихся публичным документом, доступна любому пользователю сети Интернет при переходе по ссылке <a href="https://office.bankrotstvo-fiz-lic.ru/documenti/pravila">https://office.bankrotstvo-fiz-lic.ru/documenti/pravila</a>. Администрация Сайта вправе вносить изменения в настоящие Правила. При внесении изменений в Правила Администрация Сайта уведомляет об этом пользователей путем размещения новой редакции Правил на Сайте по постоянному адресу <a href="https://office.bankrotstvo-fiz-lic.ru/documenti/politika-konfidencialnosti">https://office.bankrotstvo-fiz-lic.ru/documenti/politika-konfidencialnosti</a> не позднее, чем за 10 дней до вступления в силу соответствующих изменений.
                            <br/><br/>
                            1.4. Настоящие Правила разработаны и используются в соответствии с Правилами пользования сайтом <a href="https://office.bankrotstvo-fiz-lic.ru">https://office.bankrotstvo-fiz-lic.ru</a>, размещенными на Сайте по адресу <a href="https://office.bankrotstvo-fiz-lic.ru/documenti/pravila">https://office.bankrotstvo-fiz-lic.ru/documenti/pravila</a>.
                            <br/><br/>
                            1.5. Регистрируясь и используя Сайт, Пользователь выражает свое согласие с условиями настоящих Правил.
                            <br/><br/>
                            1.6. В случае несогласия Пользователя с условиями настоящих Правил использование Сайта и его сервисов должно быть немедленно прекращено.
                            <div className={style.h2}>
                                2. Условия пользования Сайтом
                            </div>
                            2.1. Оказывая услуги по использованию Сайта и его сервисов (далее – Услуги Сайта), Администрация Сайта, действуя разумно и добросовестно, считает, что Пользователь:
                            обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию и использовать настоящий Сайт;
                            указывает достоверную информацию о себе в объемах, необходимых для пользования Услугами Сайта;
                            ознакомлен с настоящими Правилами, выражает свое согласие с ними и принимает на себя указанные в них права и обязанности.
                            <br/><br/>
                            2.2. Администрация Сайта не проверяет достоверность получаемой (собираемой) информации о пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения Администрацией Сайта обязательств перед пользователем.
                            <div className={style.h2}>
                                3. Цели обработки информации
                            </div>
                            Администрация Сайта осуществляет обработку информации о Пользователях, в том числе их персональных данных, в целях выполнения обязательств Администрации Сайта перед Пользователями в отношении использования Сайта и его сервисов.
                            <div className={style.h2}>
                                4. Состав информации о пользователях
                            </div>
                            4.1. Персональные данные Пользователей
                            Персональные данные Пользователей включают в себя:
                            <br/><br/>
                            4.1.1. предоставляемые Пользователями и минимально необходимые для заявки на косультацию на Сайте: имя, номер мобильного телефона и адрес электронной почты;
                            <br/><br/>
                            4.1.2. предоставляемые Пользователями с использованием раздела редактирования анкеты на диагностику и составления договора;
                            <br/><br/>
                            4.1.3. дополнительно предоставляемые Пользователями по запросу Администрации Сайта в целях исполнения Администрацией Сайта обязательств перед Пользователями, вытекающих из договора на оказание Услуг Сайта. Администрация Сайта вправе, в частности, запросить у Пользователя копию документа, удостоверяющего личность, либо иного документа, содержащего имя, фамилию, фотографию Пользователя, а также иную дополнительную информацию, которая, по усмотрению Администрации Сайта, будет являться необходимой и достаточной для идентификации такого Пользователя и позволит исключить злоупотребления и нарушения прав третьих лиц.
                            <br/><br/>
                            4.2. Иная информация о Пользователях, обрабатываемая Администрацией Сайта
                            <br/><br/>
                            Администрация Сайта может также обрабатывать иную информацию о Пользователях, которая включает в себя:
                            <br/><br/>
                            4.2.1. дополнительные данные, получаемые при доступе к Сайту, включающие в себя данные о технических средствах (устройствах), технологическом взаимодействии с Сайтом (в т.ч. IP-адрес хоста, вид операционной системы пользователя, тип браузера, географическое положение, поставщик услуг Интернета, данные из адресной книги, данные, полученные в результате доступа к камере, микрофону и т.п. устройств), и последующих действиях Пользователя на Сайте.
                            <br/><br/>
                            4.2.2. информация, автоматически получаемая при доступе к Сайту с использованием закладок (cookies);
                            <br/><br/>
                            4.2.3. информация, создаваемая пользователями на Сайте вне раздела редактирования (в том числе чат);
                            <br/><br/>
                            4.2.4. информация, полученная в результате действий Пользователя на Сайте;
                            <div className={style.h2}>
                                5. Обработка информации о пользователях
                            </div>
                            5.1. Обработка персональных данных осуществляется на основе принципов:
                            <br/><br/>
                            а) законности целей и способов обработки персональных данных;
                            <br/><br/>
                            б) добросовестности;
                            <br/><br/>
                            в) соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Администрации Сайта;
                            <br/><br/>
                            г) соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;
                            <br/><br/>
                            д) недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих персональные данные.
                            <br/><br/>
                            5.1.1. Условия и цели обработки персональных данных
                            <br/><br/>
                            Администрация Сайта осуществляет обработку персональных данных пользователя в целях исполнения договора между Администрацией Сайта и Пользователем на оказание Услуг Сайта. В силу статьи 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» отдельное согласие пользователя на обработку его персональных данных не требуется. В силу п.п. 2 п. 2 статьи 22 указанного закона Администрация Сайта вправе осуществлять обработку персональных данных без уведомления уполномоченного органа по защите прав субъектов персональных данных.
                            <br/><br/>
                            5.1.2. Сбор персональных данных
                            <br/><br/>
                            Сбор персональных данных Пользователя осуществляется на Сайте при составлении заявки, а также в дальнейшем при внесении пользователем по своей инициативе дополнительных сведений о себе с помощью инструментария Сайта.
                            <br/><br/>
                            Персональные данные, предусмотренные п. 4.1.1. настоящих Правил, предоставляются Пользователем и являются минимально необходимыми при регистрации.
                            <br/><br/>
                            Персональные данные, предусмотренные п. 4.1.2. настоящих Правил, дополнительно предоставляются Пользователем по собственной инициативе с использованием раздела редактирования собственной страницы.
                            <br/><br/>
                            5.1.3. Хранение и использование персональных данных
                            <br/><br/>
                            Персональные данные пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.
                            <br/><br/>
                            5.1.4. Передача персональных данных
                            <br/><br/>
                            Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими Правилами.
                            <br/><br/>
                            При указании пользователя или при наличии согласия пользователя возможна передача персональных данных пользователя третьим лицам-контрагентам Администрации Сайта с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации, в частности, при использовании приложений.
                            <br/><br/>
                            Приложения, используемые пользователями на Сайте, размещаются и поддерживаются третьими лицами (разработчиками), которые действуют независимо от Администрации Сайта и не выступают от имени или по поручению Администрации Сайта. Пользователи обязаны самостоятельно ознакомиться с правилами оказания услуг и политикой защиты персональных данных таких третьих лиц (разработчиков) до начала использования соответствующих приложений.
                            <br/><br/>
                            Действия таких третьих лиц (разработчиков) регулируются официальным документом Администрации Сайта Условиями размещения приложений.
                            <br/><br/>
                            Предоставление персональных данных Пользователей по запросу государственных органов (органов местного самоуправления) осуществляется в порядке, предусмотренном законодательством.
                            <br/><br/>
                            В целях исполнения соглашения между Пользователем и Администрацией Сайта и предоставления Пользователю доступа к использованию функционала Сайта, Администрация Сайта развивает предоставляемые сервисы и продукты, разрабатывает и внедряет новые сервисы и продукты, оптимизирует качество сервисов и продуктов, совершенствует доступный функционал Сайта и сервисов. Для обеспечения реализации указанных целей Пользователь соглашается на осуществление Администрацией Сайта с соблюдением применимого законодательства сбора, хранения, накопления, систематизации, извлечения, сопоставления, использования, наполнения (уточнения) их данных, а также на получение и передачу аффилированным лицам и партнерам результатов автоматизированной обработки таких данных с применением различных моделей оценки информации, в виде целочисленных и/или текстовых значений и идентификаторов, соответствующих заданным в запросах оценочным критериям, для обработки данных Администрацией Сайта и/или лицами, указанными в настоящем пункте.
                            <br/><br/>
                            5.1.5. Уничтожение персональных данных
                            <br/><br/>
                            Персональные данные пользователя уничтожаются при удалении Администрацией Сайта информации.
                            <div className={style.h2}>
                                6. Права и обязанности пользователей
                            </div>
                            6.1. Пользователи вправе:
                            <br/><br/>
                            6.1.1. требовать от Администрации Сайта уточнения своих персональных данных, их блокирования или уничтожения в случае, если такие данные являются неполными, устаревшими, недостоверными, незаконно полученными или не являются необходимыми для заявленной цели обработки и если невозможно самостоятельно выполнить действия;
                            <br/><br/>
                            6.1.2. на основании запроса получать от Администрации Сайта информацию, касающуюся обработки его персональных данных.
                            <div className={style.h2}>
                                7. Меры по защите информации о Пользователях
                            </div>
                            7.1. Администрация Сайта принимает технические и организационно-правовые меры в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий.
                            <br/><br/>
                            7.2. Для авторизации доступа к Сайту используется логин (адрес электронной почты или номер мобильного телефона) и пароль Пользователя. Ответственность за сохранность данной информации несет Пользователь. Пользователь не вправе передавать собственный логин и пароль третьим лицам, а также обязан предпринимать меры по обеспечению их конфиденциальности.
                            <div className={style.h2}>
                                8. Ограничение действия Правил
                            </div>
                            Действие настоящих Правил не распространяется на действия и интернет-ресурсы третьих лиц.
                            <div className={style.h2}>
                                9. Обращения пользователей
                            </div>
                            9.1. Пользователи вправе направлять Администрации Сайта свои запросы, в том числе запросы относительно использования их персональных данных на электронный почтовый адрес aleksey-kuvaev@mail.ru или по телефону +7 (922) 960 08 40 или использовать форму для обратной связи, или онлайн поддержку.
                            <br/><br/>
                            9.2. Администрация Сайта обязуется рассмотреть и направить ответ на поступивший запрос пользователя в течение 30 дней с момента поступления обращения.
                            <br/><br/>
                            9.3. Вся корреспонденция, полученная Администрацией Сайта от пользователей (обращения в электронной форме), относится к информации ограниченного доступа и не разглашается без письменного согласия Пользователя. Персональные данные и иная информация о Пользователе, направившем запрос, не могут быть без специального согласия Пользователя использованы иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных законодательством.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocumentiPolitikaKonfidencialnostiTemplate;
