import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthForm from "../../../Components/AuthForm";
import Oplata from "../../../Components/Oplata";

import DocumentiPravila from "../../../Components/DocumentiPravila";
import DocumentiPolitikaKonfidencialnosti from "../../../Components/DocumentiPolitikaKonfidencialnosti";

import DogovorFinFull from "../../../Components/DogovorFin/DogovorFinFull";

class Main extends React.Component {
  render () {
    return (
      <Switch>
        <Route path={"/dogovor/:clientId/:type/:price/:newType"} render={
          (props) => {
            return <DogovorFinFull
              clientId={props.match.params.clientId}
              price={props.match.params.price}
              type={props.match.params.type}
              newType={true}
            />
          }
        }/>
        <Route path={"/dogovor/:clientId/:type/:price"} render={
          (props) => {
            return <DogovorFinFull
              clientId={props.match.params.clientId}
              price={props.match.params.price}
              type={props.match.params.type}
              newType={false}
            />
          }
        }/>
        <Route path={"/dogovor/:type/:price"} render={
          (props) => {
            return <DogovorFinFull
              price={props.match.params.price}
              type={props.match.params.type}
            />
          }
        }/>

        <Route path={"/oplata/:summ"} component={Oplata}/>

        <Route exact path={'/documenti/pravila'} component={DocumentiPravila}/>
        <Route exact path={'/documenti/politika-konfidencialnosti'} component={DocumentiPolitikaKonfidencialnosti}/>
        <Route path={'/'} component={AuthForm}/>
      </Switch>
    );
  }
}

export default Main;

