import { actionTypeCreateNotice, actionTypeDeleteNotice } from "./type";
import _ from 'lodash';
import { StartRender } from "../../reducers/application/actions";

const defaultState = {
  list: []
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case StartRender.type:
      return defaultState;
    case actionTypeCreateNotice:
      return {
        ...state,
        list: Array.prototype.concat(
          state.list,
          action.payload.notice
        )
      }
    case actionTypeDeleteNotice:
      if (action.payload.id) {
        _.remove(state.list, item => {
          return Number(item.id) === Number(action.payload.id);
        });

        const updatedList = state.list

        return {
          ...state,
          list: Array.prototype.concat(updatedList, [])
        };
      }
      return state;
    // case onChangeAccount.type:
    //   return defaultState;
    default:
      return state;
  }
};
