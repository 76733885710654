import React from 'react';
import PropTypes from 'prop-types';
import mainStyle from "../../../Templates/MainTemplate/MainTemplate.module.css";
import style from "./Dogovor.module.css";

class DogovorFinPreviewTemplate extends React.Component {
    getLinkForDoc = () => {
        switch(this.props.type) {
            case 'standart':
                return 'https://crm.polezniy-yurist.ru/files/base/docTemplates/bankr_hq_8.0.pdf';
            case 'full':
                return 'https://crm.polezniy-yurist.ru/files/base/docTemplates/bankr_hq_8.0.pdf';
            case 'accelerated':
                return 'https://crm.polezniy-yurist.ru/files/base/docTemplates/bankr_hq_8.0.pdf';
            case 'bankr_bez_optimiz':
                return 'https://crm.polezniy-yurist.ru/files/base/docTemplates/bankr_hq_8.0.pdf';
            case 'fin':
                return 'https://crm.polezniy-yurist.ru/files/base/docTemplates/docFin_hq_5.0.pdf';
        }
    }

    render(){
        return (
          <div className={mainStyle.page}>
              <div className={mainStyle.centered}>
                  <div className={style.dogovorPage}>
                      <h1>
                          ДОГОВОР ОКАЗАНИЯ ЮРИДИЧЕСКИХ УСЛУГ
                      </h1>

                      <div className={style.dogovor}>
                          <div className={style.inDogovor}>
                              <center>
                                  <a href={this.getLinkForDoc()} target={'_blank'}>
                                      Открыть образец договора в PDF
                                  </a>
                              </center>
                          </div>
                          <div>
                              <div className={style.acceptLayout}>
                                  <div className={style.acceptButton} onClick={this.props.onNextStep}>
                                      Перейти к заполнению
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }
}

DogovorFinPreviewTemplate.propTypes = {
    type: PropTypes.string,
    onNextStep: PropTypes.func
}

export default DogovorFinPreviewTemplate;
