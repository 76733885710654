import {onChangeConfirmationPassword} from "./actions";

class Confirmation {
  static DefaultState = {
    form: {
      password: ''
    },
    confirmed: false,
    finished: false
  };

  static reducer = (state = Confirmation.DefaultState, action = {type: '', payload: {}}) => {
    switch (action.type) {
      case onChangeConfirmationPassword.type:
        return {
          ...state,
          form: {
            ...state.form,
            password: action.payload.value
          }
        }
      default:
        return state;
    }
  }
}

export default Confirmation;
