import React from 'react';
import style from './Resheniya.module.css';
import mainStyle from '../../../Templates/MainTemplate/MainTemplate.module.css';

class ResheniyaTemplate extends React.Component {
    render() {
        return (
            <div className={mainStyle.page}>
                <div className={mainStyle.centered}>

                    <h1>
                        Последние судебные решения
                    </h1>

                    <div className={mainStyle.whitePage}>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A71-9866-2019_rezolyutivnaya_chast.pdf'} target='_blank'>
                                Татьяна Николаевна признана банкротом от 09 августа 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A43-16660-2019_rezolyutivnaya_chast.pdf'} target='_blank'>
                                Иван Васильевич признан банкротом от 08 августа 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A75-13062-2019.pdf'} target='_blank'>
                                Ольга Владимировна признана банкротом от 08 августа 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A60-31785-2019_rezolyutivnaya.pdf'} target='_blank'>
                                Александр Александрович признан банкротом от 31 июля 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A43-22092-2019_ot_22_07_2019.pdf'} target='_blank'>
                                Ирина Борисовна признана банкротом от 22 июля 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A29-4212-2019_20190704_rezolyutivnaya.pdf'} target='_blank'>
                                Эльвира Альфанасо признана банкротом от 04 июля 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A49-1158-2019_20190620_Reshenija_i_postanovlenija.pdf'} target='_blank'>
                                Валерий Эдуардович признан банкротом от 20 июня 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A07-2953-2019_2019_rezolyutivnaya.pdf'} target='_blank'>
                                Фарида Махмутовна признана банкротом от 10 июня 2019
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A75-6733-2019_20190604_Reshenija_i_postanovlenija.pdf'} target='_blank'>
                                Людмила Викторовна признана банкротом от 04 июня 2019 г
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A38-3001-2019_20190520_Reshenija_i_postanovlenija.pdf'} target='_blank'>
                                Валентина Васильевна признана банкротом от 20 мая 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A75-6790-2019_rezolyutivnaya.pdf'} target='_blank'>
                                Ирина Владимировна признана банкротом от 13 мая 2019 г.
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A75-1871-2019_20190429_Opredelenie.pdf'} target='_blank'>
                                Насима Шайкуловна признана банкротом от 29 апреля 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A28-258-2019_20190411_Reshenie_rezoljutivnaja_chast.pdf'} target='_blank'>
                                Назия Гаптрахмановна признана банкротом от 11 апреля 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A17-629-2019_20190402_Reshenie.pdf'} target='_blank'>
                                Эдуард Владимирович признан банкротом от 02 апреля 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A41-7571-2019_20190328_Reshenija_i_postanovlenija.pdf'} target='_blank'>
                                Андрей Георгиевич признан банкротом от 28 марта 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A60-6011-2019_20190326_Prochie_sudebnye_dokumenty.pdf'} target='_blank'>
                                Сергей Абдулханович признан банкротом от 26 марта 2019 года
                            </a>
                        </div>

                        <div className={style.resheniya}>
                            <a href={'https://office.bankrotstvo-fiz-lic.ru/src/images/resheniya/A65-2683-2019_Rezolyutivnaya_chast.pdf'} target='_blank'>
                                Айрат Минахметович признан банкротом от 13 марта 2019
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ResheniyaTemplate;
