import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPageInfo } from "../../store/actions/PageInfo";
import ResheniyaTemplate from "./Templates/ResheniyaTemplate";

class Resheniya extends React.Component {
    constructor(props) {
        super(props);
        props.setPageInfo({
            title: 'Судебные решения',
            description: 'Последние судебные решения в компании Полезный Юрист'
        });
    }

    render() {
        return (
            <ResheniyaTemplate/>
        );
    }
}

Resheniya.propTypes = {
    // functions
    setPageInfo: PropTypes.func,
};

export default connect(
    state => {},
    dispatcher => bindActionCreators({setPageInfo}, dispatcher)
)(Resheniya);