import {changeInputValueAuthForm, changeViewPassword, finishAuth} from "./actions";
import { registeredUser } from "../registerForm/actions";
import {StartRender} from "../../reducers/application/actions";

class AuthForm {
  static DefaultState = {
    login: '',
    password: '',
    isNeedRedirect: false,
    isViewPassword: true
  };

  static reducer = (state = AuthForm.DefaultState, action = {}) => {
    switch (action.type) {
      case changeInputValueAuthForm.type:
        return {
          ...state,
          [action.payload.name]: action.payload.value
        };
      case finishAuth.type:
        return {
          ...AuthForm.DefaultState,
          isNeedRedirect: true
        };
      case registeredUser.type:
        return AuthForm.DefaultState;
      case changeViewPassword.type:
        return {
          ...state,
          isViewPassword: !state.isViewPassword
        }
      case StartRender.type:
        return AuthForm.DefaultState;
      default:
        return state;
    }
  };
}

export default AuthForm;
