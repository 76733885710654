import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPageInfo } from "../../store/actions/PageInfo";
import UslugiTemplate from "./Tempaltes/UslugiTemplate";
import { injectObjectAsyncReducer } from "../../store";
import Anketa from '../../store/asyncReducers/Anketa';
import FirstStageForm from "../../store/asyncReducers/FirstStageForm";
import { changeFormValue } from "../../store/asyncReducers/Form/actions";
import { anketaGetResults, anketaGetPrice } from "../../store/asyncReducers/Anketa/actions";
import { changeFieldFirstStageForm, sendRequest } from "../../store/asyncReducers/FirstStageForm/actions";

class Uslugi extends React.Component {
  constructor(props) {
    super(props);
    props.setPageInfo({
      title: 'Услуги компании',
      description: 'Услуги компании'
    });
  }

  componentDidMount(){
    injectObjectAsyncReducer(Anketa);
    injectObjectAsyncReducer(FirstStageForm);
  }

  handleChangeFormValue = (name, value) => {
    this.props.changeFormValue(Anketa.name, name, value);
  }

  handleSendRequest = () => {
    const { name, phone } = this.props;
    this.props.sendRequest(name, phone);
  }

  render() {
    const { summDohod, summDolg, monthSummDolg, newSummDolg, newMonthSummDolg, state, name, phone } = this.props;
    return (
      <UslugiTemplate
        summDolg={summDolg}
        summDohod={summDohod}
        monthSummDolg={monthSummDolg}
        newSummDolg={newSummDolg}
        newMonthSummDolg={newMonthSummDolg}
        name={name}
        phone={phone}
        state={state}
        // functions
        onChangeFieldValue={this.handleChangeFormValue}
        onClickAnketaGetResults={this.props.anketaGetResults}
        onSendRequest={this.handleSendRequest}
        onChangeFirstFormField={this.props.changeFieldFirstStageForm}
        onClickGetPrice={this.props.anketaGetPrice}
      />
    );
  }
}

Uslugi.propTypes = {
  summDolg: PropTypes.string,
  summDohod: PropTypes.string,
  monthSummDolg: PropTypes.string,
  newSummDolg: PropTypes.string,
  newMonthSummDolg: PropTypes.string,
  state: PropTypes.number,
  name: PropTypes.string,
  phone: PropTypes.string,
  // functions
  setPageInfo: PropTypes.func,
  changeFormValue: PropTypes.func,
  anketaGetResults: PropTypes.func,
  changeFieldFirstStageForm: PropTypes.func,
  sendRequest: PropTypes.func,
  anketaGetPrice: PropTypes.func
};

export default connect(
  _state => {
    const anketa = _state[Anketa.name];
    const firstStageForm = _state[FirstStageForm.name];

    const { summDolg, summDohod, monthSummDolg, state, result } = Anketa.defaultState;
    const { newMonthSummDolg, newSummDolg } = result;

    let resultState = {
      summDolg,
      summDohod,
      monthSummDolg,
      newMonthSummDolg,
      newSummDolg,
      state
    };

    if (anketa) {
      const { summDolg, summDohod, monthSummDolg, state, result } = anketa;
      const { newMonthSummDolg, newSummDolg } = result;
      resultState = {
        ...resultState,
        summDolg,
        summDohod,
        monthSummDolg,
        newMonthSummDolg,
        newSummDolg,
        state
      }
    }

    if (firstStageForm) {
      const { name, phone } = firstStageForm;
      resultState = {
        ...resultState,
        name,
        phone
      }
    }

    return resultState;
  },
  dispatcher => bindActionCreators({
    setPageInfo,
    changeFormValue,
    anketaGetResults,
    anketaGetPrice,
    changeFieldFirstStageForm,
    sendRequest
  }, dispatcher)
)(Uslugi);