class Const {
  static userId = 'USER_ID';
  static utm = 'UTM';
  static jwtToken = "JWT_TOKEN";
  static userName = "USER_NAME";
  static accounts = "ACCOUNTS";
  static sendQuiz = "SEND_QUIZ";
  static region = "REGION";
}

export default Const;