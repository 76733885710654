import axios from 'axios';
import Const from './Const';
import Storage from './Storage';

class RequestCrmApi {
  post(url, data = {}) {
    // Доабвление параметров по умолчанию
    // todo Остается тут
    const sendedData = {
      ...data,
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbiI6IlRnQFhpNTBkUHVIYiF3ZGJ3NiNPUVlWUzg5R0chM2twIiwiaWQiOjExLCJpYXQiOjE1Njk1MDAzMjR9.LhJ4VkGvISGC-PK8fR9evPxw_avs7bpAMXGlOumHilw",
      clientToken: Storage.get(Const.jwtToken)
    };


    return new Promise((success, error) => {
      axios.post(
        `${process.env.REACT_APP_API_URL}/api${url}`,
        sendedData
      )
        .then((response) => {
          // this.beforeFinishinRequest(response.data);
          success(response.data);
        })
        .catch((err) => { error(err); });
    });
  }

  static post(url, data = {}) {
    // Доабвление параметров по умолчанию
    // Если в параметрах передается токен, то по умолчанию используется он. В противном случае проверяются сохраненные данные
    const sendedData = {
      ...data,
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbiI6IlRnQFhpNTBkUHVIYiF3ZGJ3NiNPUVlWUzg5R0chM2twIiwiaWQiOjExLCJpYXQiOjE1Njk1MDAzMjR9.LhJ4VkGvISGC-PK8fR9evPxw_avs7bpAMXGlOumHilw",
      clientToken: Storage.get(Const.jwtToken)
    };

    return new Promise((success, error) => {
      axios.post(
        `${process.env.REACT_APP_API_URL}/api${url}`,
        sendedData
      )
        .then((response) => {
          // this.beforeFinishinRequest(response.data);
          success(response.data);
        })
        .catch((err) => { error(err); });
    });
  }
}

export default RequestCrmApi;
