import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

class PageInfo extends React.Component {
  render() {
    return (
      <Helmet
        defaultTitle={'Полезный юрист'}
        // titleTemplate={'%s | Hello'}
        title={this.props.pageInfo.title}
        meta={[
          { 'name': 'description', 'content': this.props.pageInfo.description}
        ]}
      />
    )
  }
}

PageInfo.propTypes = {
  pageInfo: PropTypes.object,
};

export default connect(
  state => {
    return {
      pageInfo: state.pageInfo
    }
  }
)(PageInfo);
