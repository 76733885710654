import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import ProcessListTemplate from "./ProcessListTemplate";

class ProcessPageTemplate extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Col sm={12}>
            <h1>Документы</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ProcessListTemplate
              documents={this.props.documents}
              documentCategory={this.props.documentCategory}
              taskTypes={this.props.taskTypes}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

ProcessPageTemplate.propTypes = {
  documents: PropTypes.array,
  documentCategory: PropTypes.array,
  taskTypes: PropTypes.array
}

export default ProcessPageTemplate;