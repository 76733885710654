import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SuperReactComponent from "../../Super/SuperReactComponent";
import { Button } from "react-bootstrap";

class PaymentsItemTemplate extends SuperReactComponent {
  handleClickDelete = () => {
    this.props.onClickDelete(this.props.payment.id);
  }
  getConfirmed = () => {
    if (this.props.payment.isConfirmed) {
      if (this.props.payment.confirmed) {
        return (
          <Fragment>
            <td></td>
            <td>{this.props.payment.realSum.toLocaleString() + ' руб'}</td>
            <td>{ this.getStringTime(this.props.payment.confirmedDate) }</td>
            <td>{this.props.payment.confirmed.name}</td>
          </Fragment>
        )
      }
    }
    return (
      <Fragment>
        <td>
          {
            this.props.payment.hrefForPaymentSber ?
              <Button variant={"success"} onClick={() => this.handleCopyClientInfo(this.props.payment.hrefForPaymentSber)}>Скопировать</Button>
              :
              null
          }
        </td>
        <td></td>
        <td></td>
      </Fragment>
    )
  }
  handleCopyClientInfo = (text) => {
    navigator.clipboard.writeText(text);
  }

  render() {
    return (
      <tr style={this.props.payment.isConfirmed? {background: this.getStatusColor(8)} : null}>
        <td>
          {this.props.payment.id}
        </td>
        <td>
          { this.getStringTime(this.props.payment.createdAt) }
        </td>
        <td>
          { this.props.payment.summ.toLocaleString() + " руб"}
        </td>
        { this.getConfirmed() }
      </tr>
    );
  }
}

PaymentsItemTemplate.propTypes = {
  user: PropTypes.object,
  payment: PropTypes.object,
}

export default PaymentsItemTemplate;