import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { redirectFinished } from "../../../store/reducers/route/actions";

import { Redirect } from 'react-router-dom';
import ClientServices from "../../../api/ClientServices";

class Route extends React.Component {
  bloackedUrlForUnAuth = [
    '/lk'
  ];

  mainPage = '/';

  render() {
    if (!this.props.isAuth) {
      if (this.bloackedUrlForUnAuth.indexOf(ClientServices.getLocationPathName()) !== -1) {
        return <Redirect to={this.mainPage}/>;
      }
      if (this.bloackedUrlForUnAuth.indexOf(`/${ClientServices.getLocationPathName().split('/')[1]}`) !== -1) {
        return <Redirect to={this.mainPage}/>;
      }
    }
    if (this.props.doRoute) {
      this.props.redirectFinished();
      return (
        <Redirect to={this.props.path}/>
      );
    }
    return null;
  }
}

Route.propTypes = {
  isAuth: PropTypes.bool,
  path: PropTypes.string,
  doRoute: PropTypes.bool,
  // functions
  redirectFinished: PropTypes.func
};

export default connect(
  state => {
    return {
      isAuth: state.auth.isAuth,
      path: state.route.path,
      doRoute: state.route.doRoute
    };
  },
  dispatcher => bindActionCreators({ redirectFinished }, dispatcher)
)(Route);
