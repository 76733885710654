import axios from 'axios';
import Storage from "./Storage";
import Const from "./Const";

class Request {
  post(url, data = {}) {
    // Доабвление параметров по умолчанию
    // todo Остается тут
    const sendedData = {
      ...data,
      token: Storage.get(Const.jwtToken)
    };


    return new Promise((success, error) => {
      axios.post(
        `https://office.bankrotstvo-fiz-lic.ru${url}`,
        sendedData
      )
        .then((response) => {
          // this.beforeFinishinRequest(response.data);
          success(response.data);
        })
        .catch((err) => { error(err); });
    });
  }

  static post(url, data = {}) {
    // Доабвление параметров по умолчанию
    // Если в параметрах передается токен, то по умолчанию используется он. В противном случае проверяются сохраненные данные
    const sendedData = {
      ...data,
      token: data.token ? data.token : Storage.get(Const.jwtToken)
    };

    return new Promise((success, error) => {
      axios.post(
        `https://office.bankrotstvo-fiz-lic.ru${url}`,
        sendedData
      )
        .then((response) => {
          // this.beforeFinishinRequest(response.data);
          success(response.data);
        })
        .catch((err) => { error(err); });
    });
  }
}

export default Request;
