import Const from './Const';
import Cookie from './Cookie';

class Storage extends Const {

  static storage = window.localStorage;
  storage = window.localStorage;

  constructor () {
    super();
  }

  static set (name, value) {
    console.log(name, value);
    let savingValue;
    if (typeof value !== 'string') {
      savingValue = JSON.stringify(value);
    } else {
      savingValue = value;
    }
    if (localStorage) {
      this.storage.setItem(name, savingValue);
    }
    // Дублируем в куки
    Cookie.setItem(name, value);

    return Storage;
  }

  static setArray (...cortages) {
    cortages.map((cortage) => {
      if (cortage !== null) {
        this.set(cortage[0], cortage[1]);
      }
    });
  }

  static get (name, defaultValue) {
    let value = defaultValue;
    if (localStorage) {
      value = this.storage.getItem(name);
    } else {
      // Если нет локал сторы, то пробуем полуить из куков
      value = Cookie.getItem(name);
    }
    return value;
  }

  static remove (name) {
    if (localStorage) {
      this.storage.removeItem(name);
    }
    // Дублируем для куков
    Cookie.removeItem(name);
    return Storage;
  }

  static removeArray (...names) {
    names.map((name) => {
      this.remove(name);
    });
  }

  set (name, value) {
    let savingValue;
    if (typeof value !== 'string') {
      savingValue = JSON.stringify(value);
    } else {
      savingValue = value;
    }
    if (localStorage) {
      this.storage.setItem(name, savingValue);
    }
    Cookie.setItem(name, value);
    return Storage;
  }

  setArray (...cortages) {
    cortages.map((cortage) => {
      this.set(cortage[0], cortage[1]);
    });
  }

  get (name, defaultValue) {
    let value = defaultValue;

    if (localStorage) {
      value = this.storage.getItem(name);
    } else {
      value = Cookie.getItem(name);
    }

    return value;
  }

  remove (name) {
    if (localStorage) {
      this.storage.removeItem(name);
    }
    Cookie.removeItem(name);
    return Storage;
  }

  removeArray (...names) {
    names.map((name) => {
      this.remove(name);
    });
  }

  static removeNullItems(array) {
    const resultArray = [];

    Object.keys(array).forEach(key => {
      if (array[key] !== null) {
        resultArray.push(array[key]);
      }
    });

    return resultArray;
  }
}

export default Storage;
