import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Uslugi from "../../../../Components/Uslugi";
import Resheniya from "../../../../Components/Resheniya";

import Dogovor from "../../../../Components/Dogovor";
import Oplata from "../../../../Components/Oplata";

import DocumentiPravila from "../../../../Components/DocumentiPravila";
import DocumentiPolitikaKonfidencialnosti from "../../../../Components/DocumentiPolitikaKonfidencialnosti";

import Page404 from "../../../../Components/Page404";
import DogovorFinFull from "../../../../Components/DogovorFin/DogovorFinFull";
import Info from "../../../../Components/Info";
import { Container } from "react-bootstrap";
import Process from "../../../../Components/Process";
import Events from "../../../../Components/Events";
import Payments from "../../../../Components/Payments/Payments";



class ForClientRoute extends React.Component {
  render () {
    return (
      <Switch>
        <Route exact path={'/'} render={
          () => {
            return <Info/>;
          }
        }/>
        <Route path={'/process'} render={() => {
          return <Process/>;
        }}/>
        <Route path={'/events'} render={() => {
          return <Events/>;
        }}/>
        <Route path={'/payments'} render={() => {
          return <Payments/>;
        }}/>
        <Route path={"/dogovor/:clientId/:type/:price/:newType"} render={
          (props) => {
            return <DogovorFinFull
              clientId={props.match.params.clientId}
              price={props.match.params.price}
              type={props.match.params.type}
              newType={true}
            />
          }
        }/>
        <Route path={"/dogovor/:clientId/:type/:price"} render={
          (props) => {
            console.log('needed', props.match.params.clientId);
            return <DogovorFinFull
              clientId={props.match.params.clientId}
              price={props.match.params.price}
              type={props.match.params.type}
              newType={false}
            />
          }
        }/>
        <Route path={"/dogovor/:type/:price"} render={
          (props) => {
            return <DogovorFinFull
              price={props.match.params.price}
              type={props.match.params.type}
            />
          }
        }/>
        <Route path={"/dogovor/:price"} render={
          (props) => {
            return <Dogovor
              price={props.match.params.price}
            />
          }
        }/>
        <Route exact path={'/dogovor'} component={Dogovor}/>
        <Route path={"/oplata/:summ"} component={Oplata}/>

        <Route exact path={'/documenti/pravila'} component={DocumentiPravila}/>
        <Route exact path={'/documenti/politika-konfidencialnosti'} component={DocumentiPolitikaKonfidencialnosti}/>
        <Route exact path={'/uslugi'} component={Uslugi}/>
        <Route exact path={'/resheniya'} component={Resheniya}/>

        <Route page={'/'} component={Page404}/>
      </Switch>
    );
  }
}

export default ForClientRoute;
