import React from 'react';
import PropTypes from 'prop-types';
import mainStyle from "../../../Templates/MainTemplate/MainTemplate.module.css";
import style from "./Uslugi.module.css";
import Quiz from "../../Services/Popup/Quiz";


class UslugiTemplate extends React.Component {
    handleChangeFieldValue = (event) => {
        const { name, value } = event.target;
        this.props.onChangeFieldValue(name, value);
    }

    handleChangeFieldFirstForm = (event) => {
        const { name, value } = event.target;
        this.props.onChangeFirstFormField(name, value);
    }

    render() {
        const { summDolg, summDohod, monthSummDolg, newSummDolg, newMonthSummDolg, name, phone } = this.props;
        return (
            <div className={mainStyle.page}>
                <div className={mainStyle.centered}>
                        <Quiz/>
                </div>
            </div>
        );
    }
}

UslugiTemplate.propTypes = {
    summDolg: PropTypes.string,
    summDohod: PropTypes.string,
    monthSummDolg: PropTypes.string,
    newSummDolg: PropTypes.string,
    newMonthSummDolg: PropTypes.string,
    state: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    //functions
    onChangeFieldValue: PropTypes.func,
    onClickAnketaGetResults: PropTypes.func,
    onChangeFirstFormField: PropTypes.func,
    onSendRequest: PropTypes.func,
    onClickGetPrice: PropTypes.func
};

export default UslugiTemplate;