import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form, Tabs, Tab} from "react-bootstrap";

class InfoTemplate extends Component {
  render(){
    return (
      <Fragment>
        <Row>
          <Col sm={12}>
            <h1>Информация</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tabs defaultActiveKey="all-info" id="uncontrolled-tab-example">
              <Tab eventKey="all-info" title="Общая информация">
                <Row>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label>Фамилия</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.surname}/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label>Имя</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.name}/>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label>Отчество</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.secondname}/>
                    </Form.Group>
                  </Col>
                  <Col lg={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Адрес проживания</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.address}/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={6}>
                    <Form.Group>
                      <Form.Label>Процедура</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.procedure.name}/>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="svyz" title="Связь">
                <Row>
                  <Col lg={6} sm={6}>
                    <Form.Group>
                      <Form.Label>Телефон</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.phone}/>
                    </Form.Group>
                  </Col>
                  <Col lg={6} sm={6}>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.email}/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={6}>
                    <Form.Group>
                      <Form.Label>Вконтакте</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.vk}/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={6}>
                    <Form.Group>
                      <Form.Label>WhatsApp</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.whatsapp}/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={6}>
                    <Form.Group>
                      <Form.Label>Viber</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.viber}/>
                    </Form.Group>
                  </Col>
                  <Col lg={3} sm={6}>
                    <Form.Group>
                      <Form.Label>Telegram</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.telegram}/>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="passport" title="Паспортные данные">
                <Row>
                  <Col lg={4} sm={12}>
                    <Form.Group>
                      <Form.Label>Серия паспорта</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.passportSeria}/>
                    </Form.Group>
                  </Col>
                  <Col lg={8} sm={12}>
                    <Form.Group>
                      <Form.Label>Номер паспорта</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.passportNumber}/>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Когда выдан паспорт</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.passportWhenReceived}/>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Кем выдан паспорт</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.passportIssuedBy}/>
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>Прописка</Form.Label>
                      <Form.Control readOnly={true} defaultValue={this.props.address}/>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

InfoTemplate.propTypes = {
  surname: PropTypes.string,
  name: PropTypes.string,
  secondname: PropTypes.string,
  address: PropTypes.string,
  // Информация для связи
  phone: PropTypes.string,
  email: PropTypes.string,
  vk: PropTypes.string,
  whatsapp: PropTypes.string,
  viber: PropTypes.string,
  telegram: PropTypes.string,
  // Паспортные данные
  passportSeria: PropTypes.string,
  passportNumber: PropTypes.string,
  passportIssuedBy: PropTypes.string,
  passportWhenReceived: PropTypes.string
}

export default InfoTemplate;