import { SuperAction } from "../../asyncReducers/SuperAction";

export class StartRender extends SuperAction {}

export function onStartRender() {
  return new StartRender();
}

export class OnSetStatusCode extends SuperAction {}

export function setStatusCode(statusCode) {
  console.log('set status code');
  return new OnSetStatusCode({ statusCode });
}