import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStatusCode } from "../../store/reducers/application/actions";

class Page404 extends React.Component{
    constructor(props){
        super(props)
        props.setStatusCode(404);
    }
    render(){
        this.props.setStatusCode(404);
        return(
            <div>
                Такой страницы не существует
            </div>
        )
    }
}

Page404.propTypes = {
    setStatusCode: PropTypes.func
}

export default connect(
    state => {
        return {
            statusCode: state.application.statusCode
        }
    },
    dispatch => bindActionCreators({ setStatusCode }, dispatch)
)(Page404);