import { actionTypeCreateNotice, actionTypeDeleteNotice } from "./type";
import { NoticeTypes } from "../../../Components/Services/Notices/NoticeTypes";

export function someError() {
  return errorNotice('Извините, но ...', 'Что-то пошло не так');
}
export function errorNotice(title, message) {
  return createNotice(title, message, NoticeTypes.Error);
}

export function successNotice(title, message) {
  return createNotice(title, message, NoticeTypes.Success);
}

export function createNotice(title, message, type) {
  return {
    type: actionTypeCreateNotice,
    payload: {
      notice: {
        id: Date.now(),
        title,
        message,
        type
      }
    }
  };
}

export function deleteNotice(id) {
  return {
    type: actionTypeDeleteNotice,
    payload: {
      id
    }
  };
}
