import { SuperAction } from "../SuperAction";
import Request from "../../../api/Request";
import { successNotice, errorNotice, someError } from "../../reducers/notices/action";
import {createConfirmation, finishConfirmation} from "../Confirmation/actions";
import RequestCrmApi from "../../../api/RequestCrmApi";

export class onNextStepDogovor extends SuperAction {}

export function nextStepDogovor() {
  return new onNextStepDogovor();
}

export class onPrevStepDogovor extends SuperAction {}

export function prevStepDogovor() {
  return new onPrevStepDogovor();
}

export class onChangeFieldDogovor extends SuperAction {}

export function changeFieldDogovor(name, value) {
  return new onChangeFieldDogovor({ name, value })
}

export class creatingDogovor extends SuperAction {}
export class createdDogovor extends SuperAction {}
export class failureCreatingDogovor extends SuperAction {}

export function createDogovor(dogovor, price, type = 'standard', crm = false, clientId) {
  if (crm) {
    return dispatcher => {
      dispatcher(new creatingDogovor());
      RequestCrmApi.post('/clients/dogovor/create', { dogovor, price, type, clientId })
        .then(response => {
          if (response.error !== null) {
            return dispatcher(errorNotice('Ошибка', response.error.message));
          }
          console.log(response);
          dispatcher(new createdDogovor(response.dogovor));

          return dispatcher(successNotice('Договор создан', 'Подпишите договор'));
        })
        .catch(err => {
          console.error(err);
          dispatcher(new failureCreatingDogovor());
          return dispatcher(someError());
        });
    }
  }
  return dispatcher => {
    dispatcher(new creatingDogovor());
    Request.post('/dogovor/create', { dogovor, price, type })
      .then(response => {
        if (response.error !== null) {
          return dispatcher(errorNotice('Ошибка', response.error.message));
        }

        dispatcher(new createdDogovor({ path: response.dogovor.path, dogovorId: response.dogovor.dogovorId }));

        return dispatcher(successNotice('Договор создан', 'Подпишите договор'));
      })
      .catch(err => {
        console.error(err);
        dispatcher(new failureCreatingDogovor());
        return dispatcher(someError());
      });
  }
}

export class onStartSignDogovor extends SuperAction {}

export function signDogovor(phone, dogovorId, crm = false) {
  console.log(dogovorId);
  return dispatch => {
    dispatch(new onStartSignDogovor());
    return dispatch(createConfirmation('SIGN_DOGOVOR', phone, dogovorId, crm));
  }

}

export class onFinishSignDogovor extends SuperAction {}

export function finishDogovor(password, crm = false) {
  return dispatch => {
    return dispatch(finishConfirmation('SIGN_DOGOVOR', password, crm));
  }
}

export class LoadingClientInfo extends SuperAction {}
export class LoadedClientInfo extends SuperAction {}
export class FailureLoadingClientInfo extends SuperAction {}

export function loadClientInfo(clientId) {
  return SuperAction.unifyAction(
    [ LoadingClientInfo, LoadedClientInfo, FailureLoadingClientInfo],
    {
      url: '/clients/fio',
      data: { clientId }
    },
    null,
    true
  )
}