import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPageInfo } from "../../store/actions/PageInfo";
import DogovorTemplate from './Tempaltes/DogovorTemplate';

import {injectObjectAsyncReducer} from "../../store";
import DogovorReducer from '../../store/asyncReducers/Dogovor'
import ConfirmationReducer from '../../store/asyncReducers/Confirmation'
import {
  changeFieldDogovor,
  signDogovor,
  finishDogovor,
  nextStepDogovor
} from "../../store/asyncReducers/Dogovor/actions";
import { changeConfirmationPassword } from "../../store/asyncReducers/Confirmation/actions";
import { createDogovor } from "../../store/asyncReducers/Dogovor/actions";

class Dogovor extends React.Component {
  constructor(props) {
    super(props);
    props.setPageInfo({
      title: 'Договор | Полезный юрист',
      description: 'Договор | Полезный юрист'
    })
  }

  handleCreateDogovor = () => {
    this.props.createDogovor(this.props.form, this.props.price);
  }

  componentDidMount() {
    console.log(this.props.price);
    injectObjectAsyncReducer(DogovorReducer);
    injectObjectAsyncReducer(ConfirmationReducer);
  }

  handleStartSignDogovor = () => {
    this.props.signDogovor(this.props.form.phone, this.props.dogovor.dogovorId);
  }

  handleFinishSignDogovor = () => {
    this.props.finishDogovor(this.props.confirmationPassword);
  }

  render() {
    return (
        <DogovorTemplate
            step={this.props.step}
            form={this.props.form}
            price={Number(this.props.price)}
            confirmationPassword={this.props.confirmationPassword}
            onChangeField={this.props.changeFieldDogovor}
            onCreateDogovor={this.handleCreateDogovor}
            onStartSignDogovor={this.handleStartSignDogovor}
            onFinishSignDogovor={this.handleFinishSignDogovor}
            onChangeConfirmationPassword={this.props.changeConfirmationPassword}
            onNextStep={this.props.nextStepDogovor}
        />
    );
  }
}

Dogovor.propTypes = {
  price: PropTypes.number,
  state: PropTypes.number,
  form: PropTypes.object,
  confirmationPassword: PropTypes.string,
  // functions
  setPageInfo: PropTypes.func,
  changeFieldDogovor: PropTypes.func,
  createDogovor: PropTypes.func,
  signDogovor: PropTypes.func,
  finishDogovor: PropTypes.func,
  changeConfirmationPassword: PropTypes.func,
  nextStepDogovor: PropTypes.func
};

export default connect(
    state => {
      const reducer = state[DogovorReducer.name];
      const confirmationReducer = state[ConfirmationReducer.name];

      let result = {
        ...DogovorReducer.DefaultState,
        confirmationPassword: ''
      };

      if (reducer) {
        result = reducer;
      }

      if (confirmationReducer) {
        result = {
          ...result,
          confirmationPassword: confirmationReducer.form.password
        }
      }

      return  result;
    },
    dispatcher => bindActionCreators({
      setPageInfo,
      changeFieldDogovor,
      createDogovor,
      signDogovor,
      finishDogovor,
      changeConfirmationPassword,
      nextStepDogovor
    }, dispatcher)
)(Dogovor);
