import React from 'react';
import PropTypes from 'prop-types';
import style from './Footer.module.css';
import mainStyle from '../../../Templates/MainTemplate/MainTemplate.module.css';

class FooterTemplate extends React.Component {
    render() {
        return (
          <div>
              <div className={style.footer}>
                  <div className={mainStyle.centered}>
                      <div className={style.top}>

                          <div className={style.footerLogo}>
                              <a href={'/'}>
                                  <img src={'https://office.bankrotstvo-fiz-lic.ru/src/images/for_main_landing/white-logo.jpg'} title={'Полезный юрист'} alt={'Полезный юрист'} />
                              </a>
                          </div>

                          <div className={style.phone}>
                              <a href={'tel:88002349894'} onClick={this.props.onClickPhone}>8 (800) 234 98 94</a> <br />
                              Звонок по России бесплатный
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

FooterTemplate.propTypes = {
    onClickPhone: PropTypes.func
}

export default FooterTemplate;
